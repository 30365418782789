// react
import React from 'react';
import { useHistory } from 'react-router-dom';

// third-party
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';
import { injectIntl } from 'react-intl';

// application
import PageHeader from '../shared/PageHeader';
import Product from '../shared/Product';
import ProductTabs from './ProductTabs';
import database from '../../firebase/firebase';

// blocks
import BlockProductsCarousel from '../blocks/BlockProductsCarousel';

// widgets
import WidgetProducts from '../widgets/WidgetProducts';

// data stubs

import products from '../../data/shopProducts';
import theme from '../../data/theme';


function ShopPageProduct(props) {
    const { layout, sidebarPosition, match, intl } = props;
    const history = useHistory();
    const { formatMessage } = intl;
    let product;

    if (match.params.productId) {
        const docRef = database.collection('products').doc(match.params.productId);
        docRef.get().then((doc) => {
            if (doc.exists) {
                console.log('Document data:', doc.data());
            } else {
                // doc.data() will be undefined in this case
                console.log('No such document!');
            }
        }).catch((error) => {
            console.log('Error getting document:', error);
        });
        product = products.find(x => x.type === match.params.productId);
    } else {
        product = products[products.length - 1];
    }
    if (!product) {
        history.push('/404');
        return null;
    }

    const breadcrumb = [
        { title: formatMessage({ id: 'menu.Home', defaultMessage: 'Home' }), url: '/' },
        { title: formatMessage({ id: `menu.Products.${product.name}`, defaultMessage: product.name }), url: '' },
    ];

    let content;

    if (layout === 'sidebar') {
        const sidebar = (
            <div className="shop-layout__sidebar">
                <div className="block block-sidebar">
                    <div className="block-sidebar__item d-none d-lg-block">
                        <WidgetProducts title="Latest Products" products={products.slice(0, 5)} />
                    </div>
                </div>
            </div>
        );

        content = (
            <div className="container">
                <div className={`shop-layout shop-layout--sidebar--${sidebarPosition}`}>
                    {sidebarPosition === 'start' && sidebar}
                    <div className=" shop-layout__content">
                        <div className=" block">
                            <Product product={product} layout={layout} />
                            <ProductTabs withSidebar product={product} />
                        </div>
                    </div>
                    {sidebarPosition === 'end' && sidebar}
                </div>
            </div>
        );
    } else {
        content = (
            <React.Fragment>

                <div className="block">
                    <div className="container">
                        <Product product={product} layout={layout} />
                        <ProductTabs product={product} />
                    </div>
                </div>

            </React.Fragment>
        );
    }

    return (
        <React.Fragment>
            <Helmet>
                <title>{`${product.name} — ${theme.name}`}</title>
            </Helmet>

            <PageHeader breadcrumb={breadcrumb} />

            {content}
        </React.Fragment>
    );
}

ShopPageProduct.propTypes = {
    /** one of ['standard', 'sidebar', 'columnar', 'quickview'] (default: 'standard') */
    layout: PropTypes.oneOf(['standard', 'sidebar', 'columnar', 'quickview']),
    /**
     * sidebar position (default: 'start')
     * one of ['start', 'end']
     * for LTR scripts "start" is "left" and "end" is "right"
     */
    sidebarPosition: PropTypes.oneOf(['start', 'end']),
};

ShopPageProduct.defaultProps = {
    layout: 'standard',
    sidebarPosition: 'start',
};

export default injectIntl(ShopPageProduct);
