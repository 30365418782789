/**
 * NOTE: This file is ignored from git tracking. In a CI environment it is
 * generated by firebase-ci based on config in .firebaserc (see .gitlab-ci.yaml).
 * This is done so that environment specific settings can be applied.
 */

export const env = 'local';

// Config for firebase
// export const firebase = {
//     apiKey: 'AIzaSyDJug58hyPaSdD9TzypCnw8ck3Z9oxnN_g',
//     authDomain: 'pumpdevice.firebaseapp.com',
//     databaseURL: 'https://pumpdevice.firebaseio.com',
//     projectId: 'pumpdevice',
//     storageBucket: 'pumpdevice.appspot.com',
//     messagingSenderId: '153727951937',
//     appId: '1:153727951937:web:c50f0a722513ba6f'
// };

// Config to override default reduxFirebase config in store/createStore
// which is not environment specific.
// For more details, visit http://react-redux-firebase.com/docs/api/enhancer.html
// export const reduxFirebase = {
//   enableLogging: false, // enable/disable Firebase Database Logging
// };

// export default {
//   env,
//   firebase,
//   reduxFirebase
// };
