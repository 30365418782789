// react
import React, { Component } from 'react';

// third-party
import PropTypes from 'prop-types';
import InputRange from 'react-input-range';

// application
// import Currency from '../shared/Currency';


export default class FilterSlider extends Component {
    static propTypes = {
        value: PropTypes.number,
        min: PropTypes.number,
        max: PropTypes.number,
        step: PropTypes.number,
        content: PropTypes.string,
        // isPrice: PropTypes.bool,
        unit: PropTypes.string,
    };

    static defaultProps = {
        value: undefined,
        min: 0,
        max: 100,
        step: 1,
        content: null,
        // isPrice: false,
        unit: null,
    };

    state = {
        value: this.props.value,
    };

    // componentDidUpdate(nextProps) {
    //     // This will erase any local state updates!
    //     // Do not do this.
    //     this.setState({ value: nextProps.value });
    // }

    shouldComponentUpdate(nextProps, nextState) {
        const { value: nextPropsValue } = nextProps;
        const { value: propsValue } = this.props;

        const { value } = this.state;

        if (nextPropsValue !== propsValue && nextPropsValue !== value) {
            this.setState({
                value: nextPropsValue,
            });
        }

        return value !== nextState.value;
    }

    handleChange = (value) => {
        const { onChange } = this.props;
        if (onChange) onChange(value);
        this.setState(() => ({
            value,
        }));
    };

    render() {
        const { value: stateValue } = this.state;
        const {
            min,
            max,
            step,
            value: propsValue,
            content,
            // isPrice,
            unit,
        } = this.props;

        const value = stateValue || propsValue;

        let contentview;
        if (content) {
            contentview = (
                <div className="filter-price__title1 col-3 col-sm-2 col-lg-4">
                    {content}
                    {/* {':'} */}
                    {/* <span className="filter-price__min-value">{isPrice ? <Currency value={value} /> : unit ? `${value} ${unit}` : value }</span> */}
                </div>
            );
        }

        return (
            <div className="filter-price">
                <div className="filter-price__slider row  align-items-center">
                    {contentview}
                    <div className="col-9 col-sm-10 col-lg-8">
                        <InputRange
                            formatLabel={value => `${value} ${unit}`}
                            minValue={min}
                            maxValue={max}
                            value={value}
                            step={step}
                            onChange={this.handleChange}
                        />
                    </div>
                </div>
            </div>
        );
    }
}
