// react
import React, { Component } from 'react';

// third-party
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
// import { compose } from 'recompose';

// application
// import Pagination from '../shared/Pagination';
import ProductCard from '../shared/ProductCard';
import { LayoutGrid16x16Svg, LayoutGridWithDetails16x16Svg, LayoutList16x16Svg } from '../../svg';
import { actions } from '../../store/filters';
// import withFilters from '../../store/filters';

class ProductsView extends Component {
    static propTypes = {
        /**
         * array of product objects
         */
        products: PropTypes.array,
        /**
         * products list layout (default: 'grid')
         * one of ['grid', 'grid-with-features', 'list']
         */
        layout: PropTypes.oneOf(['grid', 'grid-with-features', 'list']),
        /**
         * products list layout (default: 'grid')
         * one of ['grid-3-sidebar', 'grid-4-full', 'grid-5-full']
         */
        grid: PropTypes.oneOf(['grid-3-sidebar', 'grid-4-full', 'grid-5-full']),
    };

    static defaultProps = {
        products: [],
        layout: 'grid',
        grid: 'grid-3-sidebar',
    };

    state = {
        // page: 1,
    };

    handleSortByMaxPrice = () => {
        const { startSortByMaxPrice } = this.props;
        startSortByMaxPrice();
    }

    handleSortByMinPrice = () => {
        const { startSortByMinPrice } = this.props;
        startSortByMinPrice();
    }

    handleSortByMaxWater = () => {
        const { startSortByMaxWater } = this.props;
        startSortByMaxWater();
    }

    handleSortByMinWater = () => {
        const { startSortByMinWater } = this.props;
        startSortByMinWater();
    }

    handleSortByMaxHead = () => {
        const { startSortByMaxHead } = this.props;
        startSortByMaxHead();
    }

    handleSortByMinHead = () => {
        const { startSortByMinHead } = this.props;
        startSortByMinHead();
    }
    // handleSetMaxPrice = (e) => {
    //     const { startSetMaxPrice } = this.props;
    //     startSetMaxPrice(e.target.value);
    // }

    // handleSetMinPrice = (e) => {
    //     const { startSetMinPrice } = this.props;
    //     startSetMinPrice(e.target.value);
    // }

    // handleSetCategory = (e) => {
    //     const { startSetCategory } = this.props;
    //     startSetCategory(e.target.value);
    // }

    // handleSetBrand = (e) => {
    //     const { startSetBrand } = this.props;
    //     startSetBrand(e.target.value);
    // }

    setLayout = (layout) => {
        this.setState(() => ({ layout }));
    };

    // handlePageChange = (page) => {
    //     this.setState(() => ({ page }));
    // };

    handleChange = (event) => {
        const values = event ? event.target.value : '';
        switch (values) {
        case 'sortedMinHydraulichead':
            this.handleSortByMinHead();
            break;
        case 'sortedMaxHydraulichead':
            this.handleSortByMaxHead();
            break;
        case 'sortedMinWater':
            this.handleSortByMinWater();
            break;
        case 'sortedMaxWater':
            this.handleSortByMaxWater();
            break;
        default:
            this.handleSortByMinHead();
            break;
        }
    }

    componentWillMount() {
        this.handleChange();
    }

    render() {
        const { products, grid, layout: propsLayout } = this.props;
        const { /* page, */ layout: stateLayout } = this.state;
        const layout = stateLayout || propsLayout;

        let viewModes = [
            { key: 'grid', title: 'Grid', icon: <LayoutGrid16x16Svg /> },
            { key: 'grid-with-features', title: 'Grid With Features', icon: <LayoutGridWithDetails16x16Svg /> },
            { key: 'list', title: 'List', icon: <LayoutList16x16Svg /> },
        ];

        viewModes = viewModes.map((viewMode) => {
            const className = classNames('layout-switcher__button', {
                'layout-switcher__button--active': layout === viewMode.key,
            });

            return (
                <button
                    key={viewMode.key}
                    title={viewMode.title}
                    type="button"
                    className={className}
                    onClick={() => this.setLayout(viewMode.key)}
                >
                    {viewMode.icon}
                </button>
            );
        });

        const productsList = products.map(product => (
            <div key={product.id} className="products-list__item">
                <ProductCard product={product} />
            </div>
        ));

        return (
            <div className="products-view">
                <div className="products-view__options">
                    <div className="view-options">
                        {/* <div className="view-options__layout">
                            <div className="layout-switcher">
                                <div className="layout-switcher__list">
                                    {viewModes}
                                </div>
                            </div>
                        </div> */}
                        {/* <div className="view-options__legend">Showing 6 of 98 products</div> */}
                        <div className="view-options__divider" />
                        <div className="view-options__control">
                            {/* <label htmlFor="view-options-sort">Sort By</label>
                            <div>
                                <select className="form-control form-control-sm" name="" id="view-options-sort" onChange={this.handleChange}>
                                    <option value="sortedMinHydraulichead">Default</option>
                                    <option value="sortedMinWater">Min. Water</option>
                                    <option value="sortedMaxWater">Max. Water</option>
                                    <option value="sortedMinHydraulichead">Min. Hydraulichead</option>
                                    <option value="sortedMaxHydraulichead">Max. Hydraulichead</option>
                                </select>
                            </div> */}
                        </div>
                        {/* <div className="view-options__control">
                            <label htmlFor="view-options-limit">Show</label>
                            <div>
                                <select className="form-control form-control-sm" name="" id="view-options-limit">
                                    <option value="">12</option>
                                    <option value="">24</option>
                                </select>
                            </div>
                        </div> */}
                    </div>
                </div>

                <div
                    className="products-view__list products-list"
                    data-layout={layout !== 'list' ? grid : layout}
                    data-with-features={layout === 'grid-with-features' ? 'true' : 'false'}
                >
                    <div className="products-list__body">
                        {productsList}
                    </div>
                </div>

                {/* <div className="products-view__pagination">
                    <Pagination
                        current={page}
                        siblings={2}
                        total={10}
                        onPageChange={this.handlePageChange}
                    />
                </div> */}
            </div>
        );
    }
}

const mapDispatchToProps = dispatch => ({
    startSortByMaxPrice: () => dispatch(actions.sortByMaxPrice()),
    startSortByMinPrice: () => dispatch(actions.sortByMinPrice()),
    startSortByMinWater: () => dispatch(actions.sortByMinWater()),
    startSortByMaxWater: () => dispatch(actions.sortByMaxWater()),
    startSortByMinHead: () => dispatch(actions.sortByMinHead()),
    startSortByMaxHead: () => dispatch(actions.sortByMaxHead()),
    // startSetMinPrice: price => dispatch(setMinPrice(price)),
    // startSetMaxPrice: price => dispatch(setMaxPrice(price)),
    // startSetCategory: category => dispatch(setCategory(category)),
    // startSetBrand: brand => dispatch(setBrand(brand)),
});

export default connect(null, mapDispatchToProps)(ProductsView);
