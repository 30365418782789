// react
import React, { Component } from 'react';

// third-party
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import { Link } from 'react-router-dom';

// application
import ProductTabDescription from './ProductTabDescription';
import ProductTabSpecification from './ProductTabSpecification';
import ProductTabReviews from './ProductTabReviews';
// import {
//     sortByMaxPrice,
//     sortByMinPrice,
//     setMinPrice,
//     setMaxPrice,
//     setBrand,
//     setCategory,
// } from '../../store/filters';

class ProductTabs extends Component {
    static propTypes = {
        withSidebar: PropTypes.bool,
    };

    static defaultProps = {
        withSidebar: false,
    };

    state = {
        currentTab: 'description',
    };

    setTab = (newTab) => {
        this.setState(() => ({ currentTab: newTab }));
    };

    // handleSortByMaxPrice = () => {
    //     const { startSortByMaxPrice } = this.props;
    //     startSortByMaxPrice();
    // }

    // handleSortByMinPrice = () => {
    //     const { startSortByMinPrice } = this.props;
    //     startSortByMinPrice();
    // }

    // handleSetMaxPrice = (e) => {
    //     const { startSetMaxPrice } = this.props;
    //     startSetMaxPrice(e.target.value);
    // }

    // handleSetMinPrice = (e) => {
    //     const { startSetMinPrice } = this.props;
    //     startSetMinPrice(e.target.value);
    // }

    // handleSetCategory = (e) => {
    //     const { startSetCategory } = this.props;
    //     startSetCategory(e.target.value);
    // }

    // handleSetBrand = (e) => {
    //     const { startSetBrand } = this.props;
    //     startSetBrand(e.target.value);
    // }

    render() {
        const { currentTab } = this.state;
        const { product, withSidebar, intl } = this.props;
        const { formatMessage } = intl;
        const classes = classNames('product-tabs', {
            'product-tabs--layout--sidebar': withSidebar,
        });

        const tabs = [
            {
                key: 'description',
                title: formatMessage({ id: 'Description', defaultMessage: 'Description' }),
                content: product.descriptionImages.map((image, index) => (
                    <img src={image} alt="" />
                ))
            },
            // { key: 'specification', title: 'Specification', content: <ProductTabSpecification /> },
            // { key: 'reviews', title: 'Reviews', content: <ProductTabReviews /> },
        ];

        const tabsButtons = tabs.map((tab) => {
            const classes = classNames('product-tabs__item', {
                'product-tabs__item--active': currentTab === tab.key,
            });

            return <button key={tab.key} type="button" onClick={() => this.setTab(tab.key)} className={classes}>{tab.title}</button>;
        });

        const tabsContent = tabs.map((tab) => {
            const classes = classNames('product-tabs__pane', {
                'product-tabs__pane--active': currentTab === tab.key,
            });

            return <div key={tab.key} className={classes}>{tab.content}</div>;
        });

        return (
            <div className={classes}>
                <div className="product-tabs__list">
                    {tabsButtons}
                </div>
                <div className="product-tabs__content">
                    {tabsContent}
                </div>
            </div>
        );
    }
}

// const mapDispatchToProps = dispatch => ({
//     startSortByMaxPrice: () => dispatch(sortByMaxPrice()),
//     startSortByMinPrice: () => dispatch(sortByMinPrice()),
//     startSetMinPrice: price => dispatch(setMinPrice(price)),
//     startSetMaxPrice: price => dispatch(setMaxPrice(price)),
//     startSetCategory: category => dispatch(setCategory(category)),
//     startSetBrand: brand => dispatch(setBrand(brand)),
// });

export default injectIntl(connect(/*mapDispatchToProps*/)(ProductTabs));
