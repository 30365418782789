// react
import React from 'react';

// third-party
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';
import { Redirect, Route, Switch } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';

// application
import Footer from './footer';
import Header from './header';
import NavPanel from './header/NavPanel';
import MobileHeader from './mobile/MobileHeader';
import MobileMenu from './mobile/MobileMenu';
import Quickview from './shared/Quickview';

import SitePageAboutUs from './site/SitePageAboutUs';
import SitePageContactUs from './site/SitePageContactUs';
import SitePageNotFound from './site/SitePageNotFound';
import ShopPageProduct from './shop/ShopPageProduct';
import ShopPageCategory from './shop/ShopPageCategory';
import SitePageSandPrevention from './site/SitePageSandPrevention';

// data stubs
import theme from '../data/theme';


function Layout(props) {
    const { match, headerLayout, homeComponent } = props;

    return (
        <React.Fragment>
            <Helmet>
                <title>{theme.name}</title>
                {/* <meta name="description" content={theme.fullName} /> */}
                <meta name="keywords" content="不鏽鋼沉水泵浦,沉水馬達,溫泉馬達,農業灌溉,大樓揚水泵浦" />
                <meta name="description" content="高技實業有限公司位於台灣苗栗縣苑裡鎮，為不銹鋼沉水馬達、不銹鋼沉水泵浦，優質專業製造廠,專營深井泵浦、農用灌溉泵浦、溫泉馬達、海水專用泵浦、管中泵、大樓揚水泵" />
                {/* <meta property="og:url" content="https://www.tnppump.com.tw/index.html" /> */}
                <meta property="og:title" content="苗栗不鏽鋼沉水泵浦,沉水馬達,溫泉馬達,農業灌溉,大樓揚水泵浦,優質專業製造廠 - 高技實業有限公司" />
                <meta property="og:description" content="高技實業有限公司位於台灣苗栗縣苑裡鎮，為不銹鋼沉水馬達、不銹鋼沉水泵浦，優質專業製造廠,專營深井泵浦、農用灌溉泵浦、溫泉馬達、海水專用泵浦、管中泵、大樓揚水泵" />
            </Helmet>

            <ToastContainer autoClose={5000} hideProgressBar />

            <Quickview />

            <MobileMenu />

            <div className="site">
                <header className="site__header d-lg-none">
                    <MobileHeader />
                </header>
                <header className="site__header d-lg-block d-none">
                    <Header layout={headerLayout} />
                </header>
                <div className="site__header d-lg-block d-none site-header__nav-panel sticky-top">
                    <NavPanel layout={headerLayout} />
                </div>
                <div className="site__body">
                    <Switch>
                        {/*
                        // Home
                        */}
                        <Route exact path={`${match.path}`} component={homeComponent} />

                        {/*
                        // products
                        */}
                        <Route
                            exact
                            path="/products"
                            render={props => (
                                <ShopPageCategory {...props} columns={3} viewMode="grid" sidebarPosition="start" />
                            )}
                        />
                        <Redirect from="/product.html" to="/products" />
                        <Route exact path="/products/:productId" component={ShopPageProduct} />
                        <Route
                            exact
                            path="/products/product-standard"
                            render={props => (
                                <ShopPageProduct {...props} layout="standard" />
                            )}
                        />
                        {/*
                        // Site
                        */}
                        <Route exact path="/about-us" component={SitePageAboutUs} />
                        <Route exact path="/contact-us" component={SitePageContactUs} />
                        <Route exact path="/sandprevention" component={SitePageSandPrevention} />
                        <Redirect from="/map.html" to="/contact-us" />

                        {/*
                        // Page Not Found
                        */}
                        <Route component={SitePageNotFound} />
                    </Switch>
                </div>

                <footer className="site__footer">
                    <Footer />
                </footer>
            </div>
        </React.Fragment>
    );
}

Layout.propTypes = {
    /**
     * header layout (default: 'classic')
     * one of ['classic', 'compact']
     */
    headerLayout: PropTypes.oneOf(['default', 'compact']),
    /**
     * home component
     */
    homeComponent: PropTypes.elementType.isRequired,
};

Layout.defaultProps = {
    headerLayout: 'default',
};

export default Layout;
