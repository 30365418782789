// react
import React from 'react';

// third-party
import { Helmet } from 'react-helmet';
import { injectIntl } from 'react-intl';
import { Formik, ErrorMessage } from 'formik';
import ReCAPTCHA from 'react-google-recaptcha';
import { toast } from 'react-toastify';

// application
import PageHeader from '../shared/PageHeader';
import { functions } from '../../firebase/firebase';
// blocks
import BlockMap from '../blocks/BlockMap';

// data stubs
// import theme from '../../data/theme';


// eslint-disable-next-line react/prefer-stateless-function
class SitePageSandPrevention extends React.Component {
    constructor(props, ...args) {
        super(props, ...args);
        this.state = {
            ReCAPTCHAvalue: null,
        };
        this.reCaptchaRef = React.createRef();
        this.nameRef = React.createRef();
        this.telephoneRef = React.createRef();
        this.emailRef = React.createRef();
        this.subjectRef = React.createRef();
        this.messageRef = React.createRef();
    }

    handleSubmit= (event) => {
        event.preventDefault();
        // const data = new FormData(event.target);

        // alert(JSON.stringify(data, null, '\t'));
    }

    render() {
        const { intl } = this.props;
        const { formatMessage } = intl;
        const breadcrumb = [
            { title: formatMessage({ id: 'menu.Home', defaultMessage: 'Home' }), url: '' },
            { title: formatMessage({ id: 'submenu.SandPreventionSystem', defaultMessage: 'Sand Prevention' }), url: '' },
        ];
        return (
            <React.Fragment>
                <Helmet>
                    <title>{formatMessage({ id: 'submenu.SandPreventionSystem', defaultMessage: 'Sand Prevention' })}</title>
                </Helmet>

                <PageHeader header={formatMessage({ id: 'submenu.SandPreventionSystem', defaultMessage: 'Sand Prevention' })} breadcrumb={breadcrumb} />

                <div className="block">
                    <div className="container">
                        <div className="card-group">
                            <div className="card col-12">
                                <div className="card-body contact-us">
                                    {/* <h4 className="contact-us__header card-title">{formatMessage({ id: 'ContactUs.LeaveUsAMessage', defaultMessage: 'Leave us a Message' })}</h4> */}
                                    <img src="images/products/SandPrevention/introduction.jpeg" alt="" />
                                    <br/><br/>
                                    <img src={`images/products/SandPrevention/${formatMessage({ id: 'image.SandPreventionSystem.introduction', defaultMessage: 'introduction_TW.jpeg' })}`} alt="" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}

export default injectIntl(SitePageSandPrevention);
