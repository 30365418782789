export default [
    {
        id: 1,
        type: 'building',
        name: '協辰牌大樓',
        items: [
            {
                id: 1,
                name: 'Category',
                type: 'checkbox',
                label: 'Tags',
                items: [
                    {
                        id: 1,
                        label: '50HZ',
                        tag: 'normal',
                        count: 14,
                        checked: true,
                        disabled: false,
                    },
                    {
                        id: 2,
                        label: '60HZ',
                        tag: '60hz',
                        count: 12,
                        checked: true,
                        disabled: false,
                    },
                ],
            },
        ],
    },
];
