import { toast } from 'react-toastify';
import {
    PRODUCT_ADD_ITEM, PRODUCT_REMOVE_ITEM, PRODUCT_UPDATE_QUANTITIES, PRODUCT_FILTER_CATE,
} from './productsActionTypes';

import database from '../../firebase/firebase';

export function PRODUCTfilterCategorySuccess(products = []) {
    // toast.success(`Product "${product.name}" added to PRODUCT!`);
    return {
        type: PRODUCT_FILTER_CATE,
        products,
    };
}
export function PRODUCTfilterCategory(category) {
    const docRef = database.collection('products');


    // return dispatch => {
    //     dispatch(fetchProductsPending());
    //     fetch('https://exampleapi.com/products')
    //     .then(res => res.json())
    //     .then(res => {
    //         if(res.error) {
    //             throw(res.error);
    //         }
    //         dispatch(fetchProductsSuccess(res.products);
    //         return res.products;
    //     })
    //     .catch(error => {
    //         dispatch(fetchProductsError(error));
    //     })
    // }
    // sending request to server, timeout is used as a stub
    return dispatch => (
        new Promise((resolve) => {
            const products = [];
            setTimeout(() => {
                docRef.where('category', '==', category)
                    .get()
                    .then((querySnapshot) => {
                        querySnapshot.forEach((doc) => {
                        // doc.data() is never undefined for query doc snapshots
                            products.push(doc.data());
                            // console.log(doc.id, ' => ', doc.data());
                        });
                        dispatch(PRODUCTfilterCategorySuccess(products));
                    })
                    .catch((error) => {
                        console.log('Error getting documents: ', error);
                    });
                resolve();
            }, 2000);
        })
    );
}

export function PRODUCTAddItemSuccess(product, options = [], quantity = 1) {
    toast.success(`Product "${product.name}" added to PRODUCT!`);

    return {
        type: PRODUCT_ADD_ITEM,
        product,
        options,
        quantity,
    };
}

export function PRODUCTRemoveItemSuccess(itemId) {
    return {
        type: PRODUCT_REMOVE_ITEM,
        itemId,
    };
}

export function PRODUCTUpdateQuantitiesSuccess(quantities) {
    return {
        type: PRODUCT_UPDATE_QUANTITIES,
        quantities,
    };
}

export function PRODUCTAddItem(product, options = [], quantity = 1) {
    // sending request to server, timeout is used as a stub
    return dispatch => (
        new Promise((resolve) => {
            setTimeout(() => {
                dispatch(PRODUCTAddItemSuccess(product, options, quantity));
                resolve();
            }, 2000);
        })
    );
}

export function PRODUCTRemoveItem(itemId) {
    // sending request to server, timeout is used as a stub
    return dispatch => (
        new Promise((resolve) => {
            setTimeout(() => {
                dispatch(PRODUCTRemoveItemSuccess(itemId));
                resolve();
            }, 2000);
        })
    );
}

export function PRODUCTUpdateQuantities(quantities) {
    // sending request to server, timeout is used as a stub
    return dispatch => (
        new Promise((resolve) => {
            setTimeout(() => {
                dispatch(PRODUCTUpdateQuantitiesSuccess(quantities));
                resolve();
            }, 2000);
        })
    );
}
