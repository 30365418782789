
export default (products,
    {
        sortBy, minPrice, maxPrice, category, brand, minWater, maxWater, minHead, maxHead,
        tag,
    }) => products.filter((product) => {
    let maxPriceMatch;
    let minPriceMatch;
    if (product.price === undefined) {
        maxPriceMatch = true;
        minPriceMatch = true;
    } else {
        maxPriceMatch = maxPrice ? product.price <= maxPrice : true;
        minPriceMatch = minPrice ? product.price >= minPrice : true;
    }
    // const maxPriceMatch = maxPrice ? product.price <= maxPrice : true;
    // const minPriceMatch = minPrice ? product.price >= minPrice : true;

    let maxWaterMatch;
    let minWaterMatch;
    if (product.watervolume === undefined) {
        maxWaterMatch = true;
        minWaterMatch = true;
    } else {
        maxWaterMatch = maxWater ? (product.watervolume ? product.watervolume <= maxWater : true) : true;
        minWaterMatch = minWater ? (product.watervolume ? product.watervolume >= minWater : true) : true;
    }
    // const maxWaterMatch = maxWater ? (product.watervolume ? product.watervolume <= maxWater : true) : true;
    // const minWaterMatch = minWater ? (product.watervolume ? product.watervolume >= minWater : true) : true;

    let maxHeadMatch;
    let minHeadMatch;
    if (product.watervolume === undefined) {
        maxHeadMatch = true;
        minHeadMatch = true;
    } else {
        maxHeadMatch = maxHead ? (product.hydraulichead ? product.hydraulichead <= maxHead : true) : true;
        minHeadMatch = minHead ? (product.hydraulichead ? product.hydraulichead >= minHead : true) : true;
    }
    // const maxHeadMatch = maxHead ? (product.hydraulichead ? product.hydraulichead <= maxHead : true) : true;
    // const minHeadMatch = minHead ? (product.hydraulichead ? product.hydraulichead >= minHead : true) : true;

    // const categoryMatch = category ? product.category === category : true;
    let categoryMatch;
    if (product.category === undefined) {
        categoryMatch = true;
    } else {
        categoryMatch = category ? new Intl.Collator('en-US', { sensitivity: 'base' }).compare(product.category, category) === 0 : true;
    }
    // const categoryMatch = category ? new Intl.Collator('en-US', { sensitivity: 'base' }).compare(product.category, category) === 0 : true;

    let brandMatch;
    if (product.brand === undefined) {
        brandMatch = true;
    } else {
        brandMatch = brand ? product.brand === brand : true;
    }
    // const brandMatch = brand ? product.brand === brand : true;

    let tagMatch;
    if (product.tag === undefined) {
        tagMatch = true;
    } else {
        tagMatch = tag ? tag.findIndex(value => value === product.tag) !== -1 : true;
    }
    // const tagMatch = tag ? tag.findIndex(value => value === product.tag) !== -1 : true;

    return minPriceMatch && maxPriceMatch && categoryMatch && brandMatch
        && maxWaterMatch && minWaterMatch && maxHeadMatch && minHeadMatch && tagMatch;
}).sort((a, b) => {
    /* if (sortBy === 'minPrice') return a.price > b.price ? 1 : -1;
    if (sortBy === 'maxPrice') return a.price < b.price ? 1 : -1;
    if (sortBy === 'minWater') {
        if (!a.watervolume || !b.watervolume) return 1;
        return a.watervolume > b.watervolume ? 1 : -1;
    }
    if (sortBy === 'maxWater') {
        if (!a.watervolume || !b.watervolume) return 1;
        return a.watervolume < b.watervolume ? 1 : -1;
    }
    if (sortBy === 'minHead') {
        if (!a.hydraulichead || !b.hydraulichead) return 1;
        return a.hydraulichead > b.hydraulichead ? 1 : -1;
    }
    if (sortBy === 'maxHead') {
        if (!a.hydraulichead || !b.hydraulichead) return 1;
        return a.hydraulichead < b.hydraulichead ? 1 : -1;
    } */
    return a.id > b.id ? 1 : -1;
});
