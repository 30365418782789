/* eslint-disable jsx-a11y/label-has-associated-control */
// react
import React from 'react';

// third-party
import PropTypes from 'prop-types';
// import { FormGroup, Label, Input } from 'reactstrap';
// import classNames from 'classnames';
// import { Label, Input } from 'reactstrap';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';

// application
import Collapse from '../shared/Collapse';
// import SpringPumpFloors from '../filters/SpringPumpFloors';
// import FilterCategories from '../filters/FilterCategories';
import FilterCheckbox from '../filters/FilterCheckbox';
import FilterColor from '../filters/FilterColor';
import FilterRange from '../filters/FilterRange';
import FilterSlider from '../filters/FilterSlider';
import FilterRadio from '../filters/FilterRadio';
import { ArrowRoundedDown12x7Svg } from '../../svg';
import { actions } from '../../store/filters';

import categories from '../../data/shopWidgetCategories';

import WidgetCategories from './WidgetCategories';

const initialState = {
    UndergroundFloor: 1,
    TopdFloor: 2,
    AbovegroundFloor: 4,
    MValue: { min: 0, max: 1000 },
    FTValue: { min: 0, max: 429 },
    GPMValue: { min: 0, max: 1000 },
    LPMValue: { min: 0, max: 5000 },
    radioSelected: '1',
    radioWaferVolume: '1',
    sliderValue: 0,
    tags: ['normal'],
};

class WidgetFilters extends React.Component {
    // const filtersList = filters.map((filter) => {
    //     let filterView;

    //     if (filter.type === 'springpumpfloors') {
    //         filterView = <SpringPumpFloors items={filter.options.items} />;
    //     } else if (filter.type === 'categories') {
    //         filterView = <FilterCategories categories={filter.options.items} />;
    //     } else if (filter.type === 'checkbox') {
    //         filterView = <FilterCheckbox items={filter.options.items} />;
    //     } else if (['checkbox', 'radio'].includes(filter.type)) {
    //         filterView = (
    //             <FilterRadio
    //                 items={filter.options.items}
    //                 name={filter.options.name}
    //             />
    //         );
    //     } else if (filter.type === 'color') {
    //         filterView = <FilterColor items={filter.options.items} />;
    //     } else if (filter.type === 'price') {
    //         filterView = (
    //             <FilterPrice
    //                 from={filter.options.from}
    //                 to={filter.options.to}
    //                 min={filter.options.min}
    //                 max={filter.options.max}
    //                 step={1}
    //             />
    //         );
    //     }

    //     return (
    //         <div key={filter.id} className="widget-filters__item">
    //             <Collapse
    //                 toggleClass="filter--opened"
    //                 render={({ toggle, setItemRef, setContentRef }) => (
    //                     <div className="filter filter--opened" ref={setItemRef}>
    //                         <button type="button" className="filter__title" onClick={toggle}>
    //                             {filter.name}
    //                             <ArrowRoundedDown12x7Svg className="filter__arrow" />
    //                         </button>
    //                         <div className="filter__body" ref={setContentRef}>
    //                             <div className="filter__container">
    //                                 {filterView}
    //                             </div>
    //                         </div>
    //                     </div>
    //                 )}
    //             />
    //         </div>
    //     );
    // });
    constructor(props) {
        super(props);
        this.state = initialState;
    }

    componentWillMount() {
        // const { tags } = this.state;
        const { category, filters } = this.props;
        let tags = [];
        let mValue;
        let gpmValue;
        if (filters) {
            const searchValue = filters.find(value => value.type === category);
            if (searchValue && searchValue.items) {
                const TagsValue = searchValue.items.find(value => value.label === 'Tags');
                if (TagsValue && TagsValue.items) {
                    tags = TagsValue.items.filter(value => value.checked).map(value => value.tag);
                }
                const HydraulicHeadValue = searchValue.items.find(value => value.label === 'HydraulicHead');
                if (HydraulicHeadValue && HydraulicHeadValue.value) {
                    mValue = HydraulicHeadValue.value;
                    this.setState({ MValue: mValue });
                }
                const WaterVolumeValue = searchValue.items.find(value => value.label === 'WaterVolume');
                if (WaterVolumeValue && WaterVolumeValue.value) {
                    gpmValue = WaterVolumeValue.value;
                    this.setState({ GPMValue: gpmValue, LPMValue: { min: gpmValue.min * 3.78541, max: gpmValue.max * 3.78541 } });
                }
            } else tags.push(category);
        }
        this.handleSetCategory(category);
        this.handleSetTag(tags);
        // this.handleFilters();
        let handleValue = this.state;
        if (mValue) handleValue = { ...handleValue, MValue: mValue };
        if (gpmValue) handleValue = { ...handleValue, GPMValue: gpmValue, LPMValue: { min: gpmValue.min * 3.78541, max: gpmValue.max * 3.78541 } };
        this.handleFilters(handleValue);
    }

    componentDidUpdate(prevProps, prevState) {
        // Typical usage (don't forget to compare props):
        const { category } = this.props;
        const { tags } = this.state;
        if (category !== prevProps.category) {
            this.handleSetCategory(category);
            this.resetState();
        }
        if (tags !== prevState.tags) {
            this.handleSetTag(tags);
        }
    }

    resetState = () => {
        const { category, filters } = this.props;
        let tags = [];
        let mValue;
        let gpmValue;
        if (filters) {
            const searchValue = filters.find(value => value.type === category);
            if (searchValue && searchValue.items) {
                const TagsValue = searchValue.items.find(value => value.label === 'Tags');
                if (TagsValue && TagsValue.items) {
                    tags = TagsValue.items.filter(value => value.checked).map(value => value.tag);
                }
                const HydraulicHeadValue = searchValue.items.find(value => value.label === 'HydraulicHead');
                if (HydraulicHeadValue && HydraulicHeadValue.value) {
                    mValue = HydraulicHeadValue.value;
                    this.setState({ MValue: mValue });
                }
                const WaterVolumeValue = searchValue.items.find(value => value.label === 'WaterVolume');
                if (WaterVolumeValue && WaterVolumeValue.value) {
                    gpmValue = WaterVolumeValue.value;
                    this.setState({ GPMValue: gpmValue, LPMValue: { min: gpmValue.min * 3.78541, max: gpmValue.max * 3.78541 } });
                }
            } else tags.push(category);
        }
        let newstate = { ...initialState, tags };
        if (mValue) newstate = { ...newstate, MValue: mValue };
        if (gpmValue) newstate = { ...newstate, GPMValue: gpmValue, LPMValue: { min: gpmValue.min * 3.78541, max: gpmValue.max * 3.78541 } };
        this.handleSetTag(tags);
        this.setState(newstate);
    }

    handleFilters = (state) => {
        const {
            GPMValue, LPMValue, MValue, FTValue, radioWaferVolume, radioSelected,
        } = state || this.state;
        if (radioWaferVolume === '1') {
            this.handleSetMinWater(GPMValue.min * 3.78541);
            this.handleSetMaxWater(GPMValue.max * 3.78541);
        } else {
            this.handleSetMinWater(LPMValue.min);
            this.handleSetMaxWater(LPMValue.max);
        }
        if (radioSelected === '1') {
            this.handleSetMinHead(MValue.min);
            this.handleSetMaxHead(MValue.max);
        } else {
            this.handleSetMinHead(FTValue.min / 3.3);
            this.handleSetMaxHead(FTValue.max / 3.3);
        }
        // this.handleSetTag(tags);
    }

    handleSetMaxWater = (value) => {
        const { startSetMaxWater } = this.props;
        startSetMaxWater(value);
    }

    handleSetMinWater = (value) => {
        const { startSetMinWater } = this.props;
        startSetMinWater(value);
    }

    handleSetMaxHead = (value) => {
        const { startSetMaxHead } = this.props;
        startSetMaxHead(value);
    }

    handleSetMinHead = (value) => {
        const { startSetMinHead } = this.props;
        startSetMinHead(value);
    }

    handleSetMaxPrice = (value) => {
        const { startSetMaxPrice } = this.props;
        startSetMaxPrice(value);
    }

    handleSetMinPrice = (value) => {
        const { startSetMinPrice } = this.props;
        startSetMinPrice(value);
    }

    handleSetCategory = (value) => {
        const { startSetCategory } = this.props;
        startSetCategory(value);
    }

    handleSetBrand = (e) => {
        const { startSetBrand } = this.props;
        startSetBrand(e.target.value);
    }

    handleSetTag = (value) => {
        const { startSetTags } = this.props;
        startSetTags(value);
    }

    FilterItem = (id, name, content) => (
        <div key={id} className="widget-filters__item">
            <Collapse
                toggleClass="filter--opened"
                render={({ toggle, setItemRef, setContentRef }) => (
                    <div className="filter filter--opened" ref={setItemRef}>
                        <button type="button" className="filter__title" onClick={toggle}>
                            {name}
                            <ArrowRoundedDown12x7Svg className="filter__arrow" />
                        </button>
                        <div className="filter__body" ref={setContentRef}>
                            <div className="filter__container">
                                {content}
                            </div>
                        </div>
                    </div>
                )}
            />
        </div>
    );

    CheckboxOnChange = (event) => {
        this.setState({ tags: event });
        console.log(`tags: ${event}`);
        this.handleSetTag(event);
    }

    MeasurementRadioOnChange = (event) => {
        this.setState({ radioSelected: event.target.value });
        console.log(`id: ${event.target.id}, value: ${event.target.value}`);
        this.handleFilters({ ...this.state, radioSelected: event.target.value });
    }

    WaterVolumeOnChange = (event) => {
        this.setState({ radioWaferVolume: event.target.value });
        console.log(`id: ${event.target.id}, value: ${event.target.value}`);
        this.handleFilters({ ...this.state, radioWaferVolume: event.target.value });
    }

    MeasurementRangeOnChange = (value) => {
        const { radioSelected } = this.state;
        if (radioSelected === '1') {
            this.setState({ MValue: value });
            this.handleFilters({ ...this.state, MValue: value });
        } else {
            this.setState({ FTValue: value });
            this.handleFilters({ ...this.state, FTValue: value });
        }
        console.log(`Measurement: ${value.min} ${value.max}`);
    }

    WaterVolumeRangeOnChange = (value) => {
        const { radioWaferVolume } = this.state;
        if (radioWaferVolume === '1') {
            this.setState({ GPMValue: value });
            this.handleFilters({ ...this.state, GPMValue: value });
        } else {
            this.setState({ LPMValue: value });
            this.handleFilters({ ...this.state, LPMValue: value });
        }
        console.log(`WaterVolume: ${value.min} ${value.max}`);
    }

    CalcuMeasurement = (Underground, Top, Aboveground) => ((Underground + Top + Aboveground) * 3.5);

    UndergroundFloorHandleChange = (value) => {
        let tmpvalue;
        this.setState((state, props) => {
            const calvalue = this.CalcuMeasurement(value, state.TopdFloor, state.AbovegroundFloor);
            tmpvalue = {
                UndergroundFloor: value,
                MValue: { min: this.getStepValueFromValue(calvalue, 1), max: state.MValue.max },
                FTValue: { min: this.getStepValueFromValue(calvalue * 3.3, 1), max: state.FTValue.max },
            };

            return (tmpvalue);
        });
        this.handleFilters({ ...this.state, tmpvalue });
    }

    TopFloorHandleChange = (value) => {
        let tmpvalue;
        this.setState((state, props) => {
            const calvalue = this.CalcuMeasurement(state.UndergroundFloor, value, state.AbovegroundFloor);
            tmpvalue = {
                TopdFloor: value,
                MValue: { min: this.getStepValueFromValue(calvalue, 1), max: state.MValue.max },
                FTValue: { min: this.getStepValueFromValue(calvalue * 3.3, 1), max: state.FTValue.max },
            };
            return (tmpvalue);
        });
        this.handleFilters({ ...this.state, tmpvalue });
    }

    AbovegroundFloorHandleChange = (value) => {
        let tmpvalue;
        this.setState((state, props) => {
            const calvalue = this.CalcuMeasurement(state.UndergroundFloor, state.TopdFloor, value);
            tmpvalue = {
                AbovegroundFloor: value,
                MValue: { min: this.getStepValueFromValue(calvalue, 1), max: state.MValue.max },
                FTValue: { min: this.getStepValueFromValue(calvalue * 3.3, 1), max: state.FTValue.max },
            };
            return (tmpvalue);
        });
        this.handleFilters({ ...this.state, tmpvalue });
    }

    getStepValueFromValue = (value, valuePerStep) => Math.round(value / valuePerStep) * valuePerStep;

    MeasurementView = ({
        radioSelected, UndergroundFloor, TopdFloor, AbovegroundFloor, MValue, FTValue,
    }) => (
        <React.Fragment>
            <div className="row mb-2">
                <div className="col">
                    <FilterRadio name={0} id={1} label="米" defaultChecked onChange={this.MeasurementRadioOnChange} />
                </div>
                <div className="col">
                    <FilterRadio name={0} id={2} label="呎" onChange={this.MeasurementRadioOnChange} />
                </div>
            </div>
            <div className="border-top">
                <FilterSlider content="地下" unit="層" value={UndergroundFloor} min={0} max={4} step={1} onChange={this.UndergroundFloorHandleChange} />
                <FilterSlider content="頂樓" unit="層" value={TopdFloor} min={1} max={3} step={1} onChange={this.TopFloorHandleChange} />
                <FilterSlider content="地面上" unit="層" value={AbovegroundFloor} min={1} max={30} step={1} onChange={this.AbovegroundFloorHandleChange} />
            </div>
            <div className="border-top">
                {
                    radioSelected === '1'
                        ? <FilterRange content="單位(米)" unit="m" from={MValue.min} to={MValue.max} min={0} max={130} onChange={this.MeasurementRangeOnChange} />
                        : <FilterRange content="單位(呎)" unit="ft" from={FTValue.min} to={FTValue.max} min={0} max={429} onChange={this.MeasurementRangeOnChange} />
                }
            </div>
        </React.Fragment>
    );

    WaterVolumeView= ({ radioWaferVolume, GPMValue, LPMValue }) => (
        <React.Fragment>
            <div className="row mb-2">
                <div className="col">
                    <FilterRadio name={1} id={1} label="加侖/分" defaultChecked onChange={this.WaterVolumeOnChange} />
                </div>
                <div className="col">
                    <FilterRadio name={1} id={2} label="公升/分" onChange={this.WaterVolumeOnChange} />
                </div>
            </div>
            <div className="border-top">
                {
                    radioWaferVolume === '1'
                        ? <FilterRange content="加侖/分" unit="GPM" from={GPMValue.min} to={GPMValue.max} min={26} max={238} onChange={this.WaterVolumeRangeOnChange} />
                        : <FilterRange content="公升/分" unit="LPM" from={LPMValue.min} to={LPMValue.max} min={100} max={900} onChange={this.WaterVolumeRangeOnChange} />
                }
            </div>
        </React.Fragment>
    )

    render() {
        const { title, filters, category, intl } = this.props;
        const { formatMessage } = intl;
        // const {
        //     radioSelected, UndergroundFloor, TopdFloor, AbovegroundFloor, MeasurementValue,
        // } = this.state;

        const filtersList = [];
        // const categoryValue = filters.find(value => value.type === 'categories');
        // filtersList.push(
        //     this.FilterItem(1, '分類',
        //         // <FilterCategories categories={categoryValue.items} />
        //         <WidgetCategories categories={categories} location="shop" />),
        // );
        if (filters) {
            const searchValue = filters.find(value => value.type === category);

            if (searchValue.items) {
                const searchList = searchValue.items.map((filter) => {
                    let filterView;
                    if (filter.type === 'Measurement') {
                        filterView = this.MeasurementView(this.state);
                    } else if (filter.type === 'WaterVolume') {
                        filterView = this.WaterVolumeView(this.state);
                    } else if (filter.type === 'checkbox') {
                        filterView = <FilterCheckbox items={filter.items} onChange={this.CheckboxOnChange} />;
                    } else if (['checkbox', 'radio'].includes(filter.type)) {
                        filterView = (
                            <FilterRadio
                                items={filter.items}
                                name={formatMessage({ id: filter.name, defaultMessage: filter.name })}
                            />
                        );
                    } else if (filter.type === 'color') {
                        filterView = <FilterColor items={filter.items} />;
                    }
                    return (
                        this.FilterItem(filter.id, formatMessage({ id: filter.name, defaultMessage: filter.name }), filterView)
                    );
                });
                filtersList.push(searchList);
            }
        }


        return (
            <div className="widget-filters widget">
                <h4 className="widget__title">{formatMessage({ id: title, defaultMessage: title })}</h4>

                <div className="widget-filters__list">
                    {filtersList}
                </div>

                {/* <div className="widget-filters__actions d-flex">
                    <button type="button" className="btn btn-primary btn-sm" onClick={this.handleFilters}>Filter</button>
                    <button type="button" className="btn btn-secondary btn-sm ml-2">Reset</button>
                    <button type="button" className="btn btn-secondary btn-sm">Reset</button>
                </div> */}
            </div>
        );
    }
}

WidgetFilters.propTypes = {
    /**
     * widget title
     */
    title: PropTypes.node,
    /**
     * array of filters
     */
    filters: PropTypes.array,
};

WidgetFilters.defaultProps = {
    filters: [],
};

const mapDispatchToProps = dispatch => ({
    startSetMinPrice: price => dispatch(actions.setMinPrice(price)),
    startSetMaxPrice: price => dispatch(actions.setMaxPrice(price)),
    startSetMinWater: WaterVolume => dispatch(actions.setMinWater(WaterVolume)),
    startSetMaxWater: WaterVolume => dispatch(actions.setMaxWater(WaterVolume)),
    startSetMinHead: Head => dispatch(actions.setMinHead(Head)),
    startSetMaxHead: Head => dispatch(actions.setMaxHead(Head)),
    startSetCategory: category => dispatch(actions.setCategory(category)),
    startSetBrand: brand => dispatch(actions.setBrand(brand)),
    startSetTags: tag => dispatch(actions.setTag(tag)),
});

export default injectIntl(connect(null, mapDispatchToProps)(WidgetFilters));
