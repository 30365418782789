export default [
    {
        title: 'Home',
        url: '/',
        // submenu: {
        //     type: 'menu',
        //     menu: [
        //         { title: 'Home 1', url: '/' },
        //         { title: 'Home 2', url: '/home-two' },
        //     ],
        // },
    },
    {
        title: 'Products',
        url: '/products',
        submenu: {
            type: 'menu',
            menu: [
                { title: 'SandPreventionSystem', url: '/sandprevention' },
                { title: 'Products', url: '/products' },
            ],
        },
        // submenu: {
        //     type: 'megamenu',
        //     menu: {
        //         size: 'nl',
        //         columns: [
        //             {
        //                 size: 6,
        //                 links: [
        //                     {
        //                         title: '50HZ',
        //                         url: '/products?fre=50',
        //                         links: [
        //                             { title: '4” Stainless steel deepwell pump', url: '/products?fre=50&type=4' },
        //                             { title: '6” Stainless steel deepwell pump', url: '/products?fre=50&type=6' },
        //                             { title: '8” Stainless steel deepwell pump', url: '/products?fre=50&type=8' },
        //                             { title: '10” Stainless steel deepwell pump', url: '/products?fre=50&type=10' },
        //                         ],
        //                     },
        //                 ],
        //             },
        //             {
        //                 size: 6,
        //                 links: [
        //                     {
        //                         title: '60HZ',
        //                         url: '/products?fre=60',
        //                         links: [
        //                             { title: '4” Stainless steel deepwell pump', url: '/products?fre=60&type=4' },
        //                             { title: '6” Stainless steel deepwell pump', url: '/products?fre=60&type=6' },
        //                             { title: '8” Stainless steel deepwell pump', url: '/products?fre=60&type=8' },
        //                         ],
        //                     },
        //                 ],
        //             },
        //         ],
        //     },
        // },
    },
    // {
    //     title: 'Account',
    //     url: '/account',
    //     submenu: {
    //         type: 'menu',
    //         menu: [
    //             { title: 'Login', url: '/account/login' },
    //             { title: 'Dashboard', url: '/account/dashboard' },
    //             { title: 'Edit Profile', url: '/account/profile' },
    //             { title: 'Order History', url: '/account/orders' },
    //             { title: 'Address Book', url: '/account/addresses' },
    //             { title: 'Change Password', url: '/account/password' },
    //         ],
    //     },
    // },
    // {
    //     title: 'Blog',
    //     url: '/blog/category-classic',
    //     submenu: {
    //         type: 'menu',
    //         menu: [
    //             { title: 'Blog Classic', url: '/blog/category-classic' },
    //             { title: 'Blog Grid', url: '/blog/category-grid' },
    //             { title: 'Blog List', url: '/blog/category-list' },
    //             { title: 'Blog Left Sidebar', url: '/blog/category-left-sidebar' },
    //             { title: 'Post Page', url: '/blog/post-classic' },
    //             { title: 'Post Without Sidebar', url: '/blog/post-full' },
    //         ],
    //     },
    // },
    {
        title: 'About Us',
        url: '/about-us',
    },
    {
        title: 'Contact Us',
        url: '/contact-us',
    },
    // {
    //     title: 'Buy Theme',
    //     url: 'https://themeforest.net/item/stroyka-tools-store-react-ecommerce-template/23909258',
    //     props: {
    //         external: true,
    //         target: '_blank',
    //     },
    // },
];
