export default [
    {
        id: 1,
        name: '4”SP01',
        descrip: '4”不銹鋼沉水式泵浦 (50Hz)',
        tag: 'normal',
        type: '50hz-sp01',
        category: 'Building',
        brand: 'Spring Pump',
        hydraulichead: 24,
        watervolume: 100,
        price: 0,
        compareAtPrice: null,
        images: ['images/products/50HZ/4_SP01.jpg', 'images/products/50HZ/4_SP01_Curve.jpg'],
        descriptionImages: ['images/products/50HZ/4_SP01_Curve.jpg'],
        badges: [], //'sale', 'new'
        rating: 4,
        reviews: 12,
        availability: 'in-stock',
        features: [
            { name: 'Horsepower', value: '1HP' },
            { name: 'Water Outlet', value: '1.5inch' },
            { name: 'Hydraulic Head', value: '24M ~ 44M' },
            { name: 'Water Volume', value: '100LPM ~ 150LPM' },
            //{ name: 'Battery Capacity', value: '2 Ah' },
        ],
        options: [],
    },
    {
        id: 2,
        name: '4”SP02',
        descrip: '4”不銹鋼沉水式泵浦 (50Hz)',
        tag: 'normal',
        type: '50hz-sp02',
        category: 'Building',
        brand: 'Spring Pump',
        hydraulichead: 24,
        watervolume: 100,
        price: 0,
        compareAtPrice: null,
        images: ['images/products/50HZ/4_SP02.jpg', 'images/products/50HZ/4_SP02_Curve.jpg'],
        descriptionImages: ['images/products/50HZ/4_SP02_Curve.jpg'],
        badges: [], //'sale', 'new'
        rating: 4,
        reviews: 12,
        availability: 'in-stock',
        features: [
            { name: 'Horsepower', value: '1HP' },
            { name: 'Water Outlet', value: '1.5inch' },
            { name: 'Hydraulic Head', value: '24M ~ 44M' },
            { name: 'Water Volume', value: '100LPM ~ 150LPM' },
            //{ name: 'Battery Capacity', value: '2 Ah' },
        ],
        options: [],
    },
    {
        id: 3,
        name: '4”SP03',
        descrip: '4”不銹鋼沉水式泵浦 (50Hz)',
        tag: 'normal',
        type: '50hz-sp03',
        category: 'Building',
        brand: 'Spring Pump',
        hydraulichead: 24,
        watervolume: 100,
        price: 0,
        compareAtPrice: null,
        images: ['images/products/50HZ/4_SP03.jpg', 'images/products/50HZ/4_SP03_Curve.jpg'],
        descriptionImages: ['images/products/50HZ/4_SP03_Curve.jpg'],
        badges: [], //'sale', 'new'
        rating: 4,
        reviews: 12,
        availability: 'in-stock',
        features: [
            { name: 'Horsepower', value: '1HP' },
            { name: 'Water Outlet', value: '1.5inch' },
            { name: 'Hydraulic Head', value: '24M ~ 44M' },
            { name: 'Water Volume', value: '100LPM ~ 150LPM' },
            //{ name: 'Battery Capacity', value: '2 Ah' },
        ],
        options: [],
    },
    {
        id: 4,
        name: '4”SP05',
        descrip: '4”不銹鋼沉水式泵浦 (50Hz)',
        tag: 'normal',
        type: '50hz-sp05',
        category: 'Building',
        brand: 'Spring Pump',
        hydraulichead: 24,
        watervolume: 100,
        price: 0,
        compareAtPrice: null,
        images: ['images/products/50HZ/4_SP05.jpg', 'images/products/50HZ/4_SP05_Curve.jpg'],
        descriptionImages: ['images/products/50HZ/4_SP05_Curve.jpg'],
        badges: [], //'sale', 'new'
        rating: 4,
        reviews: 12,
        availability: 'in-stock',
        features: [
            { name: 'Horsepower', value: '1HP' },
            { name: 'Water Outlet', value: '1.5inch' },
            { name: 'Hydraulic Head', value: '24M ~ 44M' },
            { name: 'Water Volume', value: '100LPM ~ 150LPM' },
            //{ name: 'Battery Capacity', value: '2 Ah' },
        ],
        options: [],
    },
    {
        id: 5,
        name: '4”SP08',
        descrip: '4”不銹鋼沉水式泵浦 (50Hz)',
        tag: 'normal',
        type: '50hz-sp08',
        category: 'Building',
        brand: 'Spring Pump',
        hydraulichead: 24,
        watervolume: 100,
        price: 0,
        compareAtPrice: null,
        images: ['images/products/50HZ/4_SP08.jpg', 'images/products/50HZ/4_SP08_Curve.jpg'],
        descriptionImages: ['images/products/50HZ/4_SP08_Curve.jpg'],
        badges: [], //'sale', 'new'
        rating: 4,
        reviews: 12,
        availability: 'in-stock',
        features: [
            { name: 'Horsepower', value: '1HP' },
            { name: 'Water Outlet', value: '1.5inch' },
            { name: 'Hydraulic Head', value: '24M ~ 44M' },
            { name: 'Water Volume', value: '100LPM ~ 150LPM' },
            //{ name: 'Battery Capacity', value: '2 Ah' },
        ],
        options: [],
    },
    {
        id: 6,
        name: '4”SP14',
        descrip: '4”不銹鋼沉水式泵浦 (50Hz)',
        tag: 'normal',
        type: '50hz-sp14',
        category: 'Building',
        brand: 'Spring Pump',
        hydraulichead: 24,
        watervolume: 100,
        price: 0,
        compareAtPrice: null,
        images: ['images/products/50HZ/4_SP14.jpg', 'images/products/50HZ/4_SP14_Curve.jpg'],
        descriptionImages: ['images/products/50HZ/4_SP14_Curve.jpg'],
        badges: [], //'sale', 'new'
        rating: 4,
        reviews: 12,
        availability: 'in-stock',
        features: [
            { name: 'Horsepower', value: '1HP' },
            { name: 'Water Outlet', value: '1.5inch' },
            { name: 'Hydraulic Head', value: '24M ~ 44M' },
            { name: 'Water Volume', value: '100LPM ~ 150LPM' },
            //{ name: 'Battery Capacity', value: '2 Ah' },
        ],
        options: [],
    },
    {
        id: 7,
        name: '6”SP17',
        descrip: '6”不銹鋼沉水式泵浦 (50Hz)',
        tag: 'normal',
        type: '50hz-sp17',
        category: 'Building',
        brand: 'Spring Pump',
        hydraulichead: 24,
        watervolume: 100,
        price: 0,
        compareAtPrice: null,
        images: ['images/products/50HZ/6_SP17.jpg', 'images/products/50HZ/6_SP17_Curve.jpg'],
        descriptionImages: ['images/products/50HZ/6_SP17_Curve.jpg'],
        badges: [], //'sale', 'new'
        rating: 4,
        reviews: 12,
        availability: 'in-stock',
        features: [
            { name: 'Horsepower', value: '1HP' },
            { name: 'Water Outlet', value: '1.5inch' },
            { name: 'Hydraulic Head', value: '24M ~ 44M' },
            { name: 'Water Volume', value: '100LPM ~ 150LPM' },
            //{ name: 'Battery Capacity', value: '2 Ah' },
        ],
        options: [],
    },
    {
        id: 8,
        name: '6”SP30',
        descrip: '6”不銹鋼沉水式泵浦 (50Hz)',
        tag: 'normal',
        type: '50hz-sp30',
        category: 'Building',
        brand: 'Spring Pump',
        hydraulichead: 24,
        watervolume: 100,
        price: 0,
        compareAtPrice: null,
        images: ['images/products/50HZ/6_SP30.jpg', 'images/products/50HZ/6_SP30_Curve.jpg'],
        descriptionImages: ['images/products/50HZ/6_SP30_Curve.jpg'],
        badges: [], //'sale', 'new'
        rating: 4,
        reviews: 12,
        availability: 'in-stock',
        features: [
            { name: 'Horsepower', value: '1HP' },
            { name: 'Water Outlet', value: '1.5inch' },
            { name: 'Hydraulic Head', value: '24M ~ 44M' },
            { name: 'Water Volume', value: '100LPM ~ 150LPM' },
            //{ name: 'Battery Capacity', value: '2 Ah' },
        ],
        options: [],
    },
    {
        id: 9,
        name: '6”SP46',
        descrip: '6”不銹鋼沉水式泵浦 (50Hz)',
        tag: 'normal',
        type: '50hz-sp46',
        category: 'Building',
        brand: 'Spring Pump',
        hydraulichead: 24,
        watervolume: 100,
        price: 0,
        compareAtPrice: null,
        images: ['images/products/50HZ/6_SP46.jpg', 'images/products/50HZ/6_SP46_Curve.jpg'],
        descriptionImages: ['images/products/50HZ/6_SP46_Curve.jpg'],
        badges: [], //'sale', 'new'
        rating: 4,
        reviews: 12,
        availability: 'in-stock',
        features: [
            { name: 'Horsepower', value: '1HP' },
            { name: 'Water Outlet', value: '1.5inch' },
            { name: 'Hydraulic Head', value: '24M ~ 44M' },
            { name: 'Water Volume', value: '100LPM ~ 150LPM' },
            //{ name: 'Battery Capacity', value: '2 Ah' },
        ],
        options: [],
    },
    {
        id: 10,
        name: '6”SP60',
        descrip: '6”不銹鋼沉水式泵浦 (50Hz)',
        tag: 'normal',
        type: '50hz-sp60',
        category: 'Building',
        brand: 'Spring Pump',
        hydraulichead: 24,
        watervolume: 100,
        price: 0,
        compareAtPrice: null,
        images: ['images/products/50HZ/6_SP60.jpg', 'images/products/50HZ/6_SP60_Curve.jpg'],
        descriptionImages: ['images/products/50HZ/6_SP60_Curve.jpg'],
        badges: [], //'sale', 'new'
        rating: 4,
        reviews: 12,
        availability: 'in-stock',
        features: [
            { name: 'Horsepower', value: '1HP' },
            { name: 'Water Outlet', value: '1.5inch' },
            { name: 'Hydraulic Head', value: '24M ~ 44M' },
            { name: 'Water Volume', value: '100LPM ~ 150LPM' },
            //{ name: 'Battery Capacity', value: '2 Ah' },
        ],
        options: [],
    },
    {
        id: 11,
        name: '8”SP77',
        descrip: '8”不銹鋼沉水式泵浦 (50Hz)',
        tag: 'normal',
        type: '50hz-sp77',
        category: 'Building',
        brand: 'Spring Pump',
        hydraulichead: 24,
        watervolume: 100,
        price: 0,
        compareAtPrice: null,
        images: ['images/products/50HZ/8_SP77.jpg', 'images/products/50HZ/8_SP77_Curve.jpg'],
        descriptionImages: ['images/products/50HZ/8_SP77_Curve.jpg'],
        badges: [], //'sale', 'new'
        rating: 4,
        reviews: 12,
        availability: 'in-stock',
        features: [
            { name: 'Horsepower', value: '1HP' },
            { name: 'Water Outlet', value: '1.5inch' },
            { name: 'Hydraulic Head', value: '24M ~ 44M' },
            { name: 'Water Volume', value: '100LPM ~ 150LPM' },
            //{ name: 'Battery Capacity', value: '2 Ah' },
        ],
        options: [],
    },
    {
        id: 12,
        name: '8”SP95',
        descrip: '8”不銹鋼沉水式泵浦 (50Hz)',
        tag: 'normal',
        type: '50hz-sp95',
        category: 'Building',
        brand: 'Spring Pump',
        hydraulichead: 24,
        watervolume: 100,
        price: 0,
        compareAtPrice: null,
        images: ['images/products/50HZ/8_SP95.jpg', 'images/products/50HZ/8_SP95_Curve.jpg'],
        descriptionImages: ['images/products/50HZ/8_SP95_Curve.jpg'],
        badges: [], //'sale', 'new'
        rating: 4,
        reviews: 12,
        availability: 'in-stock',
        features: [
            { name: 'Horsepower', value: '1HP' },
            { name: 'Water Outlet', value: '1.5inch' },
            { name: 'Hydraulic Head', value: '24M ~ 44M' },
            { name: 'Water Volume', value: '100LPM ~ 150LPM' },
            //{ name: 'Battery Capacity', value: '2 Ah' },
        ],
        options: [],
    },
    {
        id: 13,
        name: '10”SP125',
        descrip: '10”不銹鋼沉水式泵浦 (50Hz)',
        tag: 'normal',
        type: '50hz-sp125',
        category: 'Building',
        brand: 'Spring Pump',
        hydraulichead: 24,
        watervolume: 100,
        price: 0,
        compareAtPrice: null,
        images: ['images/products/50HZ/10_SP125.jpg', 'images/products/50HZ/10_SP125_Curve.jpg'],
        descriptionImages: ['images/products/50HZ/10_SP125_Curve.jpg'],
        badges: [], //'sale', 'new'
        rating: 4,
        reviews: 12,
        availability: 'in-stock',
        features: [
            { name: 'Horsepower', value: '1HP' },
            { name: 'Water Outlet', value: '1.5inch' },
            { name: 'Hydraulic Head', value: '24M ~ 44M' },
            { name: 'Water Volume', value: '100LPM ~ 150LPM' },
            //{ name: 'Battery Capacity', value: '2 Ah' },
        ],
        options: [],
    },
    {
        id: 14,
        name: '10”SP160',
        descrip: '10”不銹鋼沉水式泵浦 (50Hz)',
        tag: 'normal',
        type: '50hz-sp160',
        category: 'Building',
        brand: 'Spring Pump',
        hydraulichead: 24,
        watervolume: 100,
        price: 0,
        compareAtPrice: null,
        images: ['images/products/50HZ/10_SP160.jpg', 'images/products/50HZ/10_SP160_Curve.jpg'],
        descriptionImages: ['images/products/50HZ/10_SP160_Curve.jpg'],
        badges: [], //'sale', 'new'
        rating: 4,
        reviews: 12,
        availability: 'in-stock',
        features: [
            { name: 'Horsepower', value: '1HP' },
            { name: 'Water Outlet', value: '1.5inch' },
            { name: 'Hydraulic Head', value: '24M ~ 44M' },
            { name: 'Water Volume', value: '100LPM ~ 150LPM' },
            //{ name: 'Battery Capacity', value: '2 Ah' },
        ],
        options: [],
    },
    {
        id: 15,
        name: '4”SP01',
        descrip: '4”不銹鋼沉水式泵浦 (60Hz)',
        tag: '60hz',
        type: '60hz-sp01',
        category: 'Building',
        brand: 'Spring Pump',
        hydraulichead: 24,
        watervolume: 100,
        price: 0,
        compareAtPrice: null,
        images: ['images/products/60HZ/4_SP01.jpg', 'images/products/60HZ/4_SP01_Curve.jpg'],
        descriptionImages: ['images/products/60HZ/4_SP01_Curve.jpg'],
        badges: [], //'sale', 'new'
        rating: 4,
        reviews: 12,
        availability: 'in-stock',
        features: [
            { name: 'Horsepower', value: '1HP' },
            { name: 'Water Outlet', value: '1.5inch' },
            { name: 'Hydraulic Head', value: '24M ~ 44M' },
            { name: 'Water Volume', value: '100LPM ~ 150LPM' },
            //{ name: 'Battery Capacity', value: '2 Ah' },
        ],
        options: [],
    },
    {
        id: 17,
        name: '4”SP02',
        descrip: '4”不銹鋼沉水式泵浦 (60Hz)',
        tag: '60hz',
        type: '60hz-sp02',
        category: 'Building',
        brand: 'Spring Pump',
        hydraulichead: 24,
        watervolume: 100,
        price: 0,
        compareAtPrice: null,
        images: ['images/products/60HZ/4_SP02.jpg', 'images/products/60HZ/4_SP02_Curve.jpg'],
        descriptionImages: ['images/products/60HZ/4_SP02_Curve.jpg'],
        badges: [], //'sale', 'new'
        rating: 4,
        reviews: 12,
        availability: 'in-stock',
        features: [
            { name: 'Horsepower', value: '1HP' },
            { name: 'Water Outlet', value: '1.5inch' },
            { name: 'Hydraulic Head', value: '24M ~ 44M' },
            { name: 'Water Volume', value: '100LPM ~ 160LPM' },
            //{ name: 'Battery Capacity', value: '2 Ah' },
        ],
        options: [],
    },
    {
        id: 18,
        name: '4”SP03',
        descrip: '4”不銹鋼沉水式泵浦 (60Hz)',
        tag: '60hz',
        type: '60hz-sp03',
        category: 'Building',
        brand: 'Spring Pump',
        hydraulichead: 24,
        watervolume: 100,
        price: 0,
        compareAtPrice: null,
        images: ['images/products/60HZ/4_SP03.jpg', 'images/products/60HZ/4_SP03_Curve.jpg'],
        descriptionImages: ['images/products/60HZ/4_SP03_Curve.jpg'],
        badges: [], //'sale', 'new'
        rating: 4,
        reviews: 12,
        availability: 'in-stock',
        features: [
            { name: 'Horsepower', value: '1HP' },
            { name: 'Water Outlet', value: '1.5inch' },
            { name: 'Hydraulic Head', value: '24M ~ 44M' },
            { name: 'Water Volume', value: '100LPM ~ 160LPM' },
            //{ name: 'Battery Capacity', value: '2 Ah' },
        ],
        options: [],
    },
    {
        id: 19,
        name: '4”SP05',
        descrip: '4”不銹鋼沉水式泵浦 (60Hz)',
        tag: '60hz',
        type: '60hz-sp05',
        category: 'Building',
        brand: 'Spring Pump',
        hydraulichead: 24,
        watervolume: 100,
        price: 0,
        compareAtPrice: null,
        images: ['images/products/60HZ/4_SP05.jpg', 'images/products/60HZ/4_SP05_Curve.jpg'],
        descriptionImages: ['images/products/60HZ/4_SP05_Curve.jpg'],
        badges: [], //'sale', 'new'
        rating: 4,
        reviews: 12,
        availability: 'in-stock',
        features: [
            { name: 'Horsepower', value: '1HP' },
            { name: 'Water Outlet', value: '1.5inch' },
            { name: 'Hydraulic Head', value: '24M ~ 44M' },
            { name: 'Water Volume', value: '100LPM ~ 160LPM' },
            //{ name: 'Battery Capacity', value: '2 Ah' },
        ],
        options: [],
    },
    {
        id: 20,
        name: '4”SP08',
        descrip: '4”不銹鋼沉水式泵浦 (60Hz)',
        tag: '60hz',
        type: '60hz-sp08',
        category: 'Building',
        brand: 'Spring Pump',
        hydraulichead: 24,
        watervolume: 100,
        price: 0,
        compareAtPrice: null,
        images: ['images/products/60HZ/4_SP08.jpg', 'images/products/60HZ/4_SP08_Curve.jpg'],
        descriptionImages: ['images/products/60HZ/4_SP08_Curve.jpg'],
        badges: [], //'sale', 'new'
        rating: 4,
        reviews: 12,
        availability: 'in-stock',
        features: [
            { name: 'Horsepower', value: '1HP' },
            { name: 'Water Outlet', value: '1.5inch' },
            { name: 'Hydraulic Head', value: '24M ~ 44M' },
            { name: 'Water Volume', value: '100LPM ~ 160LPM' },
            //{ name: 'Battery Capacity', value: '2 Ah' },
        ],
        options: [],
    },
    {
        id: 21,
        name: '4”SP14',
        descrip: '4”不銹鋼沉水式泵浦 (60Hz)',
        tag: '60hz',
        type: '60hz-sp14',
        category: 'Building',
        brand: 'Spring Pump',
        hydraulichead: 24,
        watervolume: 100,
        price: 0,
        compareAtPrice: null,
        images: ['images/products/60HZ/4_SP14.jpg', 'images/products/60HZ/4_SP14_Curve.jpg'],
        descriptionImages: ['images/products/60HZ/4_SP14_Curve.jpg'],
        badges: [], //'sale', 'new'
        rating: 4,
        reviews: 12,
        availability: 'in-stock',
        features: [
            { name: 'Horsepower', value: '1HP' },
            { name: 'Water Outlet', value: '1.5inch' },
            { name: 'Hydraulic Head', value: '24M ~ 44M' },
            { name: 'Water Volume', value: '100LPM ~ 160LPM' },
            //{ name: 'Battery Capacity', value: '2 Ah' },
        ],
        options: [],
    },
    {
        id: 22,
        name: '6”SP17',
        descrip: '6”不銹鋼沉水式泵浦 (60Hz)',
        tag: '60hz',
        type: '60hz-sp17',
        category: 'Building',
        brand: 'Spring Pump',
        hydraulichead: 24,
        watervolume: 100,
        price: 0,
        compareAtPrice: null,
        images: ['images/products/60HZ/6_SP17.jpg', 'images/products/60HZ/6_SP17_Curve.jpg'],
        descriptionImages: ['images/products/60HZ/6_SP17_Curve.jpg'],
        badges: [], //'sale', 'new'
        rating: 4,
        reviews: 12,
        availability: 'in-stock',
        features: [
            { name: 'Horsepower', value: '1HP' },
            { name: 'Water Outlet', value: '1.5inch' },
            { name: 'Hydraulic Head', value: '24M ~ 44M' },
            { name: 'Water Volume', value: '100LPM ~ 160LPM' },
            //{ name: 'Battery Capacity', value: '2 Ah' },
        ],
        options: [],
    },
    {
        id: 23,
        name: '6”SP30',
        descrip: '6”不銹鋼沉水式泵浦 (60Hz)',
        tag: '60hz',
        type: '60hz-sp30',
        category: 'Building',
        brand: 'Spring Pump',
        hydraulichead: 24,
        watervolume: 100,
        price: 0,
        compareAtPrice: null,
        images: ['images/products/60HZ/6_SP30.jpg', 'images/products/60HZ/6_SP30_Curve.jpg'],
        descriptionImages: ['images/products/60HZ/6_SP30_Curve.jpg'],
        badges: [], //'sale', 'new'
        rating: 4,
        reviews: 12,
        availability: 'in-stock',
        features: [
            { name: 'Horsepower', value: '1HP' },
            { name: 'Water Outlet', value: '1.5inch' },
            { name: 'Hydraulic Head', value: '24M ~ 44M' },
            { name: 'Water Volume', value: '100LPM ~ 160LPM' },
            //{ name: 'Battery Capacity', value: '2 Ah' },
        ],
        options: [],
    },
    {
        id: 24,
        name: '6”SP46',
        descrip: '6”不銹鋼沉水式泵浦 (60Hz)',
        tag: '60hz',
        type: '60hz-sp46',
        category: 'Building',
        brand: 'Spring Pump',
        hydraulichead: 24,
        watervolume: 100,
        price: 0,
        compareAtPrice: null,
        images: ['images/products/60HZ/6_SP46.jpg', 'images/products/60HZ/6_SP46_Curve.jpg'],
        descriptionImages: ['images/products/60HZ/6_SP46_Curve.jpg'],
        badges: [], //'sale', 'new'
        rating: 4,
        reviews: 12,
        availability: 'in-stock',
        features: [
            { name: 'Horsepower', value: '1HP' },
            { name: 'Water Outlet', value: '1.5inch' },
            { name: 'Hydraulic Head', value: '24M ~ 44M' },
            { name: 'Water Volume', value: '100LPM ~ 160LPM' },
            //{ name: 'Battery Capacity', value: '2 Ah' },
        ],
        options: [],
    },
    {
        id: 25,
        name: '6”SP60',
        descrip: '6”不銹鋼沉水式泵浦 (60Hz)',
        tag: '60hz',
        type: '60hz-sp60',
        category: 'Building',
        brand: 'Spring Pump',
        hydraulichead: 24,
        watervolume: 100,
        price: 0,
        compareAtPrice: null,
        images: ['images/products/60HZ/6_SP60.jpg', 'images/products/60HZ/6_SP60_Curve.jpg'],
        descriptionImages: ['images/products/60HZ/6_SP60_Curve.jpg'],
        badges: [], //'sale', 'new'
        rating: 4,
        reviews: 12,
        availability: 'in-stock',
        features: [
            { name: 'Horsepower', value: '1HP' },
            { name: 'Water Outlet', value: '1.5inch' },
            { name: 'Hydraulic Head', value: '24M ~ 44M' },
            { name: 'Water Volume', value: '100LPM ~ 160LPM' },
            //{ name: 'Battery Capacity', value: '2 Ah' },
        ],
        options: [],
    },
    {
        id: 26,
        name: '8”SP77',
        descrip: '8”不銹鋼沉水式泵浦 (60Hz)',
        tag: '60hz',
        type: '60hz-sp77',
        category: 'Building',
        brand: 'Spring Pump',
        hydraulichead: 24,
        watervolume: 100,
        price: 0,
        compareAtPrice: null,
        images: ['images/products/60HZ/8_SP77.jpg', 'images/products/60HZ/8_SP77_Curve.jpg'],
        descriptionImages: ['images/products/60HZ/8_SP77_Curve.jpg'],
        badges: [], //'sale', 'new'
        rating: 4,
        reviews: 12,
        availability: 'in-stock',
        features: [
            { name: 'Horsepower', value: '1HP' },
            { name: 'Water Outlet', value: '1.5inch' },
            { name: 'Hydraulic Head', value: '24M ~ 44M' },
            { name: 'Water Volume', value: '100LPM ~ 160LPM' },
            //{ name: 'Battery Capacity', value: '2 Ah' },
        ],
        options: [],
    },
    {
        id: 27,
        name: '8”SP95',
        descrip: '8”不銹鋼沉水式泵浦 (60Hz)',
        tag: '60hz',
        type: '60hz-sp95',
        category: 'Building',
        brand: 'Spring Pump',
        hydraulichead: 24,
        watervolume: 100,
        price: 0,
        compareAtPrice: null,
        images: ['images/products/60HZ/8_SP95.jpg', 'images/products/60HZ/8_SP95_Curve.jpg'],
        descriptionImages: ['images/products/60HZ/8_SP95_Curve.jpg'],
        badges: [], //'sale', 'new'
        rating: 4,
        reviews: 12,
        availability: 'in-stock',
        features: [
            { name: 'Horsepower', value: '1HP' },
            { name: 'Water Outlet', value: '1.5inch' },
            { name: 'Hydraulic Head', value: '24M ~ 44M' },
            { name: 'Water Volume', value: '100LPM ~ 160LPM' },
            //{ name: 'Battery Capacity', value: '2 Ah' },
        ],
        options: [],
    },
];
