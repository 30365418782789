import {
    SORT_BY_MAX_PRICE,
    SORT_BY_MIN_PRICE,
    SORT_BY_MAX_WATER,
    SORT_BY_MIN_WATER,
    SORT_BY_MAX_HEAD,
    SORT_BY_MIN_HEAD,
    SET_MIN_PRICE,
    SET_MAX_PRICE,
    SET_CATEGORY,
    SET_BRAND,
    SET_TAG,
    SET_MIN_WATER,
    SET_MAX_WATER,
    SET_MIN_HEAD,
    SET_MAX_HEAD,
    SET_RESET,
} from './filtersActionTypes';


const filtersReducerDefaultState = {
    sortBy: '',
    minPrice: 0,
    maxPrice: 100000,
    minWater: 0,
    maxWater: 100000,
    minHead: 0,
    maxHead: 100000,
    category: '',
    brand: '',
    tag: [],
};

export default function filtersReducer(state = filtersReducerDefaultState, action) {
    switch (action.type) {
    case SORT_BY_MAX_PRICE:
        return {
            ...state,
            sortBy: 'maxPrice',
        };
    case SORT_BY_MIN_PRICE:
        return {
            ...state,
            sortBy: 'minPrice',
        };
    case SORT_BY_MAX_WATER:
        return {
            ...state,
            sortBy: 'maxWater',
        };
    case SORT_BY_MIN_WATER:
        return {
            ...state,
            sortBy: 'minWater',
        };
    case SORT_BY_MAX_HEAD:
        return {
            ...state,
            sortBy: 'maxHead',
        };
    case SORT_BY_MIN_HEAD:
        return {
            ...state,
            sortBy: 'minHead',
        };
    case SET_MIN_PRICE:
        return {
            ...state,
            minPrice: action.startPrice,
        };
    case SET_MAX_PRICE:
        return {
            ...state,
            maxPrice: action.endPrice,
        };
    case SET_MIN_WATER:
        return {
            ...state,
            minWater: action.startWater,
        };
    case SET_MAX_WATER:
        return {
            ...state,
            maxWater: action.endWater,
        };
    case SET_MIN_HEAD:
        return {
            ...state,
            minHead: action.startHead,
        };
    case SET_MAX_HEAD:
        return {
            ...state,
            maxHead: action.endHead,
        };
    case SET_CATEGORY:
        return {
            ...state,
            category: action.category,
        };
    case SET_TAG:
        return {
            ...state,
            tag: action.tag,
        };
    case SET_BRAND:
        return {
            ...state,
            brand: action.brand,
        };
    case SET_RESET:
        return filtersReducerDefaultState;
    default:
        return state;
    }
}
