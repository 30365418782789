import * as firebase from 'firebase';
// Required for side-effects
require('firebase/functions');
require('firebase/firestore');
require('firebase/storage');
//require('firebase/analytics');

const config = {
    apiKey: 'AIzaSyDWYwilQ_WDUTLdMp7OcQBgqW1hDCIrgAU',
    authDomain: 'tnppump.firebaseapp.com',
    projectId: 'tnppump',
    storageBucket: 'tnppump.appspot.com',
    messagingSenderId: '1044307141875',
    appId: '1:1044307141875:web:c4bc70439e6b2b64568386',
    measurementId: 'G-TNQ719DLRL'
};

firebase.initializeApp(config);
const database = firebase.firestore();
const functions = firebase.functions();
const storage = firebase.storage();
//const analytics = firebase.analytics();

// const googleAuthProvider = new firebase.auth.GoogleAuthProvider();
// const facebookAuthProvider = new firebase.auth.FacebookAuthProvider();

export {
    firebase, functions, storage, /* googleAuthProvider, facebookAuthProvider, */database as default,
};
