// react
import React from 'react';

// third-party
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';
import { connect } from 'react-redux';
import { CommonLoading } from 'react-loadingg';
import { injectIntl } from 'react-intl';

// application
import PageHeader from '../shared/PageHeader';
import ProductsView from './ProductsView';
import selectProducts from './productsFilter';
import { actions } from '../../store/products';
// import {
//     sortByMaxPrice, sortByMinPrice, setMinPrice, setMaxPrice, setBrand, setCategory,
// } from '../../store/filters';

// widgets
import WidgetFilters from '../widgets/WidgetFilters';
import WidgetProducts from '../widgets/WidgetProducts';

// data stubs
import filters from '../../data/shopFilters';
// import products from '../../data/shopProducts';
// import theme from '../../data/theme';

// eslint-disable-next-line react/prefer-stateless-function
class ShopPageCategory extends React.Component {
    componentDidMount() {
        // const {
        //     match, startProductfilterCategory, startClearProduct,
        // } = this.props;
        // let category;

        // startClearProduct();

        // if (match.params.categoryId) {
        //     category = match.params.categoryId;
        // } else {
        //     category = 'building';
        // }

        // startProductfilterCategory(category);
    }

    componentDidUpdate(prevProps) {
        // 常見用法（別忘了比較 prop）：
        // const {
        //     match, startProductfilterCategory, startClearProduct,
        // } = this.props;
        // const category = match.params.categoryId;
        // if (prevProps.match.params.categoryId !== category) {
        //     startClearProduct();
        //     startProductfilterCategory(category);
        // }
    }

    render() {
        const {
            columns, viewMode, sidebarPosition, products, match, intl,
        } = this.props;
        const { formatMessage } = intl;

        const breadcrumb = [
            { title: formatMessage({ id: 'menu.Home', defaultMessage: 'Home' }), url: '/' },
            { title: formatMessage({ id: 'menu.Products', defaultMessage: 'Products' }), url: '/products' },
        ];
        let content;

        let category;

        if (match.params.categoryId) {
            category = match.params.categoryId;
        } else {
            category = 'building';
        }

        if (columns > 3) {
            content = (
                <div className="container">
                    <div className="block">
                        <ProductsView products={products} layout={viewMode} grid={`grid-${columns}-full`} limit={15} />
                    </div>
                </div>
            );
        } else {
            const sidebar = (
                <div className="shop-layout__sidebar">
                    <div className="block block-sidebar">
                        <div className="block-sidebar__item">
                            <WidgetFilters title="Filters" filters={filters} category={category} />
                        </div>
                        {/* <div className="block-sidebar__item d-none d-lg-block">
                            <WidgetProducts title="Latest Products" products={products.slice(0, 5)} />
                        </div> */}
                    </div>
                </div>
            );

            content = (
                <div className="container">
                    <div className={`shop-layout shop-layout--sidebar--${sidebarPosition}`}>
                        {sidebarPosition === 'start' && sidebar}
                        <div className="shop-layout__content">
                            <div className="block">
                                <ProductsView products={products} layout={viewMode} grid="grid-3-sidebar" limit={15} />
                            </div>
                        </div>
                        {sidebarPosition === 'end' && sidebar}
                    </div>
                </div>
            );
            if (products.length === 0) {
                content = (
                    <div className="container">
                        <div className={`shop-layout shop-layout--sidebar--${sidebarPosition}`}>
                            {sidebarPosition === 'start' && sidebar}
                            <div className="shop-layout__content">
                                <div className="block">
                                    <CommonLoading />
                                </div>
                            </div>
                            {sidebarPosition === 'end' && sidebar}
                        </div>
                    </div>
                );
            }
        }

        return (
            <React.Fragment>
                <Helmet>
                    <title>{`${formatMessage({ id: 'menu.Products', defaultMessage: 'Products' })}`}</title>
                </Helmet>

                <PageHeader header="Pump" breadcrumb={breadcrumb} />

                {content}
            </React.Fragment>
        );
    }
}

ShopPageCategory.propTypes = {
    /**
     * number of product columns (default: 3)
     */
    columns: PropTypes.number,
    /**
     * mode of viewing the list of products (default: 'grid')
     * one of ['grid', 'grid-with-features', 'list']
     */
    viewMode: PropTypes.oneOf(['grid', 'grid-with-features', 'list']),
    /**
     * sidebar position (default: 'start')
     * one of ['start', 'end']
     * for LTR scripts "start" is "left" and "end" is "right"
     */
    sidebarPosition: PropTypes.oneOf(['start', 'end']),
};

ShopPageCategory.defaultProps = {
    columns: 3,
    viewMode: 'grid',
    sidebarPosition: 'start',
};

const mapStateToProps = state => ({
    products: selectProducts(state.products, state.filters),
    allProducts: state.products,
});

const mapDispatchToProps = dispatch => ({
    startProductfilterCategory: category => dispatch(actions.PRODUCTfilterCategory(category)),
    startClearProduct: () => dispatch(actions.PRODUCTfilterCategorySuccess()),
//     startSortByMinPrice: () => dispatch(sortByMinPrice()),
//     startSetMinPrice: price => dispatch(setMinPrice(price)),
//     startSetMaxPrice: price => dispatch(setMaxPrice(price)),
//     startSetCategory: category => dispatch(setCategory(category)),
//     startSetBrand: brand => dispatch(setBrand(brand)),
});

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(ShopPageCategory));

// export default ShopPageCategory;
