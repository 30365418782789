export default [
    {
        title: 'NT Dollar',
        currency: {
            code: 'NT',
            symbol: 'NT$',
            name: 'NT Dollar',
        },
    },
    {
        title: '$ US Dollar',
        currency: {
            code: 'USD',
            symbol: '$',
            name: 'US Dollar',
        },
    },
];
