export default {
    name: '高技實業有限公司 — TNP National Pump Industrial Co., Ltd.',
    fullName: '高技實業有限公司',
    url: '',
    author: {
        name: 'Jim',
        profile_url: '',
        email: 'weijim888@gmail.com',
    },
    contacts: {
        address: '苗栗縣苑裡鎮新復里91-5號',
        email: 'sales@tnppump.com.tw',
        phone: '(886) 37-863193 ,(886) 37-863293',
    },
};
