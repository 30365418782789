// react
import React from 'react';

// third-party
import { injectIntl } from 'react-intl';

// application
import FooterContacts from './FooterContacts';
import FooterLinks from './FooterLinks';
import FooterNewsletter from './FooterNewsletter';
import ToTop from './ToTop';

// data stubs
import theme from '../../data/theme';


function Footer({ intl }) {
    const { formatMessage } = intl;
    const informationLinks = [
        { title: formatMessage({ id: 'menu.Home' }), url: '/' },
        { title: formatMessage({ id: 'menu.Products' }), url: '/products' },
        { title: formatMessage({ id: 'menu.About Us' }), url: '/about-us' },
        { title: formatMessage({ id: 'menu.Contact Us' }), url: '/contact-us' },
        // { title: formatMessage({ id: 'menu.Mobile.Cart' }), url: '/shop/cart' },
        // { title: formatMessage({ id: 'menu.Shop' }), url: '' },
        // { title: formatMessage({ id: 'menu.Shop' }), url: '' },
    ];

    // const accountLinks = [
    //     { title: 'Store Location', url: '' },
    //     { title: 'Order History', url: '' },
    //     { title: 'Wish List', url: '' },
    //     { title: 'Newsletter', url: '' },
    //     { title: 'Specials', url: '' },
    //     { title: 'Gift Certificates', url: '' },
    //     { title: 'Affiliate', url: '' },
    // ];

    return (
        <div className="site-footer">
            <div className="container">
                <div className="site-footer__widgets">
                    <div className="row">
                        <div className="col-12 col-md-8 col-lg-8">
                            <FooterContacts />
                        </div>
                        <div className="col-12 col-md-4 col-lg-4">
                            <FooterLinks title={formatMessage({ id: 'buttom.information' })} items={informationLinks} />
                        </div>
                        {/* <div className="col-6 col-md-3 col-lg-2">
                            <FooterLinks title="My Account" items={accountLinks} />
                        </div> */}
                        {/* <div className="col-6 col-md-3 col-lg-3">
                            <FooterNewsletter />
                        </div> */}
                    </div>
                </div>

                <div className="site-footer__bottom">
                    <div className="site-footer__copyright">
                        {formatMessage({ id: 'footer.codedby' })}
                        {' '}
                        <a href={`mailto:${theme.author.email}`}>{theme.author.name}</a>
                        {/* <a href={theme.author.profile_url}
                        target="_blank" rel="noopener noreferrer">
                            {theme.author.name}
                        </a> */}
                    </div>
                    <div className="site-footer__payments">
                        <FooterNewsletter />
                        {/* <img src="images/payments.png" alt="" /> */}
                    </div>
                </div>
            </div>
            <ToTop />
        </div>
    );
}

export default injectIntl(Footer);
