// react
import React from 'react';

// third-party
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { Collapse } from 'reactstrap';

class FilterRadio extends React.Component {
    handleChange = (event) => {
        const { onChange } = this.props;
        // const text = event.target.value;
        if (onChange !== null) onChange(event);
    }

    render() {
        const {
            count, id, disabled, checked, label, name, defaultChecked, children,
        } = this.props;

        let viewcount;

        if (count) {
            viewcount = <span className="filter-list__counter">{count}</span>;
        }
        const view = (
            <div className="filter-list__list pb-2">
                <Collapse isOpen={checked}>
                    <div className="border">
                        {children}
                    </div>
                </Collapse>
            </div>
        );

        return (
            <div className="filter-list">
                <div className="filter-list__list">
                    <label
                        key={id}
                        className={classNames('filter-list__item', {
                            'filter-list__item--disabled': disabled,
                        })}
                    >
                        <span className="filter-list__input input-radio">
                            <span className="input-radio__body">
                                <input className="input-radio__input" type="radio" id={id} value={id} name={name} onChange={this.handleChange} Checked={checked} defaultChecked={defaultChecked} disabled={disabled} />
                                <span className="input-radio__circle" />
                            </span>
                        </span>
                        <span className="filter-list__title">{label}</span>
                        {viewcount}
                    </label>
                </div>
                { children && view }
            </div>
        );
    }
}

FilterRadio.propTypes = {
    count: PropTypes.number,
    id: PropTypes.number,
    disabled: PropTypes.bool,
    checked: PropTypes.bool,
    defaultChecked: PropTypes.bool,
    label: PropTypes.string,
    name: PropTypes.string,
    onChange: PropTypes.func,
};
FilterRadio.defaultProps = {
    count: null,
    id: 1,
    disabled: false,
    checked: false,
    defaultChecked: false,
    label: '',
    name: 'radio',
    onChange: null,
};

export default FilterRadio;
