// third-party
import { addLocaleData } from 'react-intl';
import localeEn from 'react-intl/locale-data/en';
import localeZh from 'react-intl/locale-data/zh';

// application
import enMessages from './messages/en';
import zhMessages from './messages/zh';


addLocaleData([...localeEn, ...localeZh]);

export default {
    en: enMessages,
    zh: zhMessages,
};
