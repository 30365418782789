// react
import React from 'react';

// third-party
import Slick from 'react-slick';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';
import { injectIntl } from 'react-intl';

// data stubs
// import theme from '../../data/theme';


const slickSettings = {
    dots: true,
    arrows: false,
    infinite: true,
    speed: 400,
    slidesToShow: 3,
    slidesToScroll: 3,
    responsive: [
        {
            breakpoint: 767,
            settings: {
                slidesToShow: 2,
                slidesToScroll: 2,
            },
        },
        {
            breakpoint: 379,
            settings: {
                slidesToShow: 1,
                slidesToScroll: 1,
            },
        },
    ],
};

function SitePageAboutUs({ intl }) {
    const { formatMessage } = intl;
    return (
        <div className="block about-us">
            <Helmet>
                <title>{`${formatMessage({ id: 'menu.About Us', defaultMessage: 'About Us' })}`}</title>
            </Helmet>

            <div className="about-us__image" style={{ backgroundImage: 'url("images/aboutus.jpg")' }} />
            <div className="container">
                <div className="row justify-content-center">
                    <div className="col-12 col-xl-10">
                        <div className="about-us__body">
                            <h1 className="about-us__title">{formatMessage({ id: 'menu.About Us', defaultMessage: 'About Us' })}</h1>
                            <div className="about-us__text typography">
                                <p>
                                    {`${formatMessage({ id: 'AboutUs.Description', defaultMessage: '' })}`}
                                </p>
                                <p>
                                    <h4>
                                        {`${formatMessage({ id: 'AboutUs.Description2', defaultMessage: '' })}`}
                                    </h4>
                                </p>
                            </div>
                            <div className="about-us__team">
                                <h2 className="about-us__team-title">{`${formatMessage({ id: 'AboutUs.Features', defaultMessage: '' })}`}</h2>
                                <div className="about-us__team-subtitle text-muted">
                                    <p>
                                        {`${formatMessage({ id: 'AboutUs.FeaturesDes1', defaultMessage: '' })}`}<br></br>
                                        {`${formatMessage({ id: 'AboutUs.FeaturesDes2', defaultMessage: '' })}`}<br></br>
                                        {`${formatMessage({ id: 'AboutUs.FeaturesDes3', defaultMessage: '' })}`}<br></br>
                                        {`${formatMessage({ id: 'AboutUs.FeaturesDes4', defaultMessage: '' })}`}<br></br>
                                        {`${formatMessage({ id: 'AboutUs.FeaturesDes5', defaultMessage: '' })}`}<br></br>
                                        {`${formatMessage({ id: 'AboutUs.FeaturesDes6', defaultMessage: '' })}`}<br></br>
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default injectIntl(SitePageAboutUs);
