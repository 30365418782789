// react
import React, { Component } from 'react';

// third-party
import InputRange from 'react-input-range';
import PropTypes from 'prop-types';

// application
import Currency from '../shared/Currency';


export default class FilterRange extends Component {
    static propTypes = {
        from: PropTypes.number,
        to: PropTypes.number,
        min: PropTypes.number,
        max: PropTypes.number,
        step: PropTypes.number,
        content: PropTypes.string,
        isPrice: PropTypes.bool,
        unit: PropTypes.string,
    };

    static defaultProps = {
        from: undefined,
        to: undefined,
        min: 0,
        max: 100,
        step: 1,
        content: null,
        isPrice: false,
        unit: null,
    };

    state = {};

    // static getDerivedStateFromProps(nextProps, prevState) {
    //     return {
    //         from: nextProps.from,
    //         to: nextProps.to,
    //     };
    // }

    // shouldComponentUpdate(nextProps, nextState) {
    //     const { onChange } = this.props;
    //     const { from: fromnextPropsValue, to: tonextPropsValue } = nextProps;
    //     const { from: frompropsValue, to: topropsValue } = this.props;
    //     const { from: stateFrom, to: stateTo } = this.state;

    //     let isUpdate = false;
    //     if (fromnextPropsValue !== frompropsValue
    //         && fromnextPropsValue !== stateFrom) {
    //         this.setState({
    //             from: fromnextPropsValue,
    //         });
    //         isUpdate = true;
    //     }
    //     if (tonextPropsValue !== topropsValue
    //         && tonextPropsValue !== stateTo) {
    //         this.setState({
    //             to: tonextPropsValue,
    //         });
    //         isUpdate = true;
    //     }
    //     if (isUpdate && onChange) onChange({ to: tonextPropsValue, from: fromnextPropsValue });
    //     return stateFrom !== nextState.from || stateTo !== nextState.to;
    // }

    handleChange = (value) => {
        const { onChange } = this.props;

        // this.setState(() => ({
        //     from: value.min,
        //     to: value.max,
        // }));

        if (onChange) onChange(value);
    }

    // value = (value) => {
    //     if (value.min && value.max) {
    //         this.setState(() => ({
    //             from: value.min,
    //             to: value.max,
    //         }));
    //     } else if (value.min) {
    //         this.setState(() => ({
    //             from: value.min,
    //         }));
    //     } else if (value.max) {
    //         this.setState(() => ({
    //             to: value.max,
    //         }));
    //     }
    // };

    render() {
        const { from: stateFrom, to: stateTo } = this.state;
        const {
            min,
            max,
            step,
            from: propsFrom,
            to: propsTo,
            content,
            isPrice,
            unit,
            onChange,
        } = this.props;

        const from = Math.max(stateFrom || propsFrom || min, min);
        const to = Math.min(stateTo || propsTo || max, max);
        if (from > to) {
            // this.setState(() => ({
            //     to: max,
            // }));
            if (onChange) onChange({ min: from, max });
        }
        let contentview;
        if (content) {
            contentview = (
                <div className="filter-price__title">
                    {content}
                    {': '}
                    <span className="filter-price__min-value">{isPrice ? <Currency value={from} /> : unit ? `${from} ${unit}` : from }</span>
                    {' – '}
                    <span className="filter-price__max-value">{isPrice ? <Currency value={to} /> : unit ? `${to} ${unit}` : to}</span>
                </div>
            );
        }

        return (
            <div className="filter-price">
                <div className="filter-price__slider">
                    <InputRange
                        minValue={min}
                        maxValue={max}
                        value={{ min: from, max: to }}
                        step={step}
                        onChange={this.handleChange}
                    />
                </div>
                {contentview}
            </div>
        );
    }
}
