/* eslint-disable class-methods-use-this */
// react
import React, { Component } from 'react';
import { Link } from 'react-router-dom';

// third-party
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';

// application
// import {
//     Fi24Hours48Svg,
//     FiFreeDelivery48Svg,
//     FiPaymentSecurity48Svg,
//     FiTag48Svg,
// } from '../../svg';


class BlockFeatures extends Component {
    // componentDidMount() {
    //     this.toggle();
    // }

    toggle() {
        if (window.isLoad()) window.toggle();
    }

    render() {
        const { layout, intl } = this.props;
        const { formatMessage } = intl;
        return (
            <div className={`block block-features block-features--layout--${layout}`}>
                <div className="container">
                    <div className="block-features__list">
                        <Link to="/about-us" className="block-features__item">
                            <div className="block-features__icon">
                                {/* <FiFreeDelivery48Svg /> */}
                                <img src="images/introduction.png" alt="" />
                            </div>
                            <div className="block-features__content">
                                <div className="block-features__title">{formatMessage({ id: 'homepage.CompanyDescription' })}</div>
                                <div className="block-features__subtitle">{formatMessage({ id: 'homepage.CompanyDescription.Des' })}</div>
                            </div>
                        </Link>
                        <div className="block-features__divider" />
                        <Link to="/products" className="block-features__item">
                            <div className="block-features__icon">
                                {/* <Fi24Hours48Svg /> */}
                                <img src="images/production.png" alt="" />
                            </div>
                            <div className="block-features__content">
                                <div className="block-features__title">{formatMessage({ id: 'homepage.ProductTour' })}</div>
                                <div className="block-features__subtitle">{formatMessage({ id: 'homepage.ProductTour.Des' })}</div>
                            </div>
                        </Link>
                        <div className="block-features__divider" />
                        <Link className="block-features__item" onClick={() => this.toggle()}>
                            <div className="block-features__icon">
                                {/* <FiPaymentSecurity48Svg /> */}
                                <img src="images/information.png" alt="" />
                            </div>
                            <div className="block-features__content">
                                <div className="block-features__title">{formatMessage({ id: 'homepage.OnlineConsultation' })}</div>
                                <div className="block-features__subtitle">{formatMessage({ id: 'homepage.OnlineConsultation.Des' })}</div>
                            </div>
                        </Link>
                        {/* <div className="block-features__item" onClick={() => window.Mtoggle()}>

                        </div> */}
                        {/* <Link to="/contact-us" className="block-features__item"> */}

                        {/* </Link> */}
                        <div className="block-features__divider" />
                        <Link to="/contact-us" className="block-features__item">
                            <div className="block-features__icon">
                                {/* <FiTag48Svg /> */}
                                <img src="images/location.png" alt="" />
                            </div>
                            <div className="block-features__content">
                                <div className="block-features__title">{formatMessage({ id: 'homepage.DeographicLocation' })}</div>
                                <div className="block-features__subtitle">{formatMessage({ id: 'homepage.DeographicLocation.Des' })}</div>
                            </div>
                        </Link>
                    </div>
                </div>
            </div>
        );
    }
}

BlockFeatures.propTypes = {
    layout: PropTypes.oneOf(['classic', 'boxed']),
};

BlockFeatures.defaultProps = {
    layout: 'classic',
};

export default injectIntl(BlockFeatures);
