// react
import React from 'react';

// third-party
import { Helmet } from 'react-helmet';
import { injectIntl } from 'react-intl';
import { Formik, ErrorMessage } from 'formik';
import ReCAPTCHA from 'react-google-recaptcha';
import { toast } from 'react-toastify';

// application
import PageHeader from '../shared/PageHeader';
import { functions } from '../../firebase/firebase';
// blocks
import BlockMap from '../blocks/BlockMap';

// data stubs
// import theme from '../../data/theme';


// eslint-disable-next-line react/prefer-stateless-function
class SitePageContactUs extends React.Component {
    constructor(props, ...args) {
        super(props, ...args);
        this.state = {
            ReCAPTCHAvalue: null,
        };
        this.reCaptchaRef = React.createRef();
        this.nameRef = React.createRef();
        this.telephoneRef = React.createRef();
        this.emailRef = React.createRef();
        this.subjectRef = React.createRef();
        this.messageRef = React.createRef();
    }

    handleSubmit= (event) => {
        event.preventDefault();
        // const data = new FormData(event.target);

        // alert(JSON.stringify(data, null, '\t'));
    }

    render() {
        const { intl } = this.props;
        const { formatMessage } = intl;
        const breadcrumb = [
            { title: formatMessage({ id: 'menu.Home', defaultMessage: 'Home' }), url: '' },
            { title: formatMessage({ id: 'menu.Contact Us', defaultMessage: 'Contact Us' }), url: '' },
        ];
        return (
            <React.Fragment>
                <Helmet>
                    <title>{formatMessage({ id: 'menu.Contact Us', defaultMessage: 'Contact Us' })}</title>
                </Helmet>

                <BlockMap />

                <PageHeader header={formatMessage({ id: 'menu.Contact Us', defaultMessage: 'Contact Us' })} breadcrumb={breadcrumb} />

                <div className="block">
                    <div className="container">
                        <div className="card-group">
                            <div className="card col-12 col-sm-8">
                                <div className="card-body contact-us">
                                    <h4 className="contact-us__header card-title">{formatMessage({ id: 'ContactUs.LeaveUsAMessage', defaultMessage: 'Leave us a Message' })}</h4>
                                    <Formik
                                        initialValues={{
                                            recaptcha: '', telephone: '', name: '', email: '', subject: '', message: '',
                                        }}
                                        validate={(values) => {
                                            const errors = {};
                                            if (!values.email) {
                                                errors.email = 'Email Required';
                                            } else if (
                                                !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)
                                            ) {
                                                errors.email = 'Invalid email address';
                                            }
                                            if (!values.name) {
                                                errors.name = 'Name Required';
                                            }
                                            if (!values.telephone) {
                                                errors.telephone = 'Telephone Required';
                                            }
                                            if (!values.recaptcha) {
                                                errors.recaptcha = 'Recaptcha Required';
                                            }
                                            return errors;
                                        }}
                                        onSubmit={(values, { setSubmitting, resetForm }) => {
                                            setSubmitting(true);

                                            const addMessage = functions.httpsCallable('sendEmail');
                                            addMessage({
                                                subject: `來自[${values.name}]的留言`,
                                                text: `姓名: ${values.name}
                                                \r\n電話: ${values.telephone}
                                                \r\n信箱: ${values.email}
                                                \r\n主題: ${values.subject}
                                                \r\n內容: ${values.message}`,
                                                mailto: 'sales@tnppump.com.tw', // 'weijim888@gmail.com, jim22107@yahoo.com.tw',
                                            });
                                            this.reCaptchaRef.current.reset();
                                            toast.success(`"${values.name}" 您好，感謝您的來信，我們會盡速與您聯絡。`);
                                            resetForm();
                                            setSubmitting(false);
                                            this.nameRef.current.value = '';
                                            this.telephoneRef.current.value = '';
                                            this.emailRef.current.value = '';
                                            this.subjectRef.current.value = '';
                                            this.messageRef.current.value = '';
                                        }}
                                    >
                                        {({
                                            /* values,
                                            errors,
                                            touched, */
                                            handleChange,
                                            handleBlur,
                                            handleSubmit,
                                            isSubmitting,
                                            setFieldValue,
                                            /* and other goodies */
                                        }) => (
                                            <form onSubmit={handleSubmit}>
                                                <div className="form-group">
                                                    <label htmlFor="form-name">{formatMessage({ id: 'ContactUs.YourName', defaultMessage: 'Your Name' })}</label>
                                                    <input ref={this.nameRef} type="name" name="name" id="form-name" className="form-control" onChange={handleChange} onBlur={handleBlur} placeholder={formatMessage({ id: 'ContactUs.YourName', defaultMessage: 'Your Name' })} />
                                                </div>
                                                <ErrorMessage style={{ color: 'red' }} name="name" component="div" className="form-group" />
                                                <div className="form-row">
                                                    <div className="form-group col-md-6">
                                                        <label htmlFor="form-telephone">{formatMessage({ id: 'ContactUs.Phone', defaultMessage: 'Phone' })}</label>
                                                        <input ref={this.telephoneRef} type="tel" name="telephone" id="form-telephone" className="form-control" onChange={handleChange} onBlur={handleBlur} placeholder={formatMessage({ id: 'ContactUs.Phone', defaultMessage: 'Phone' })} />
                                                    </div>
                                                    <div className="form-group col-md-6">
                                                        <label htmlFor="form-email">{formatMessage({ id: 'ContactUs.Email', defaultMessage: 'Email' })}</label>
                                                        <input
                                                            ref={this.emailRef}
                                                            type="email"
                                                            name="email"
                                                            id="form-email"
                                                            className="form-control"
                                                            onChange={handleChange}
                                                            onBlur={handleBlur}
                                                            placeholder={formatMessage({ id: 'ContactUs.EmailAddress', defaultMessage: 'Email Address' })}
                                                        />
                                                    </div>
                                                </div>
                                                <ErrorMessage style={{ color: 'red' }} name="telephone" component="div" className="form-group" />
                                                <ErrorMessage style={{ color: 'red' }} name="email" component="div" className="form-group" />
                                                <div className="form-group">
                                                    <label htmlFor="form-subject">{formatMessage({ id: 'ContactUs.Subject', defaultMessage: 'Subject' })}</label>
                                                    <input ref={this.subjectRef} type="subject" name="subject" id="form-subject" className="form-control" onChange={handleChange} onBlur={handleBlur} placeholder={formatMessage({ id: 'ContactUs.Subject', defaultMessage: 'Subject' })} />
                                                </div>
                                                <div className="form-group">
                                                    <label htmlFor="form-message">{formatMessage({ id: 'ContactUs.Message', defaultMessage: 'Message' })}</label>
                                                    <textarea ref={this.messageRef} type="message" name="message" id="form-message" className="form-control" rows="4" onChange={handleChange} onBlur={handleBlur} />
                                                </div>
                                                <div className="form-row d-flex justify-content-between align-items-center">
                                                    <ReCAPTCHA
                                                        ref={this.reCaptchaRef}
                                                        sitekey="6LeEKHofAAAAAACF3nhQ3bHI0w8nUQ3r8kYCXzpm"
                                                        className="form-group contact-us__recaptcha"
                                                        type="recaptcha"
                                                        name="recaptcha"
                                                        onChange={(response) => { setFieldValue('recaptcha', response); }}
                                                    />
                                                    <button type="submit" disabled={isSubmitting} className="btn btn-primary float-right">{formatMessage({ id: 'ContactUs.SendMessage', defaultMessage: 'Send Message' })}</button>
                                                </div>
                                                <ErrorMessage style={{ color: 'red' }} name="recaptcha" component="div" className="form-group" />
                                            </form>
                                        )}
                                    </Formik>
                                </div>
                            </div>
                            <div className="card col-12 col-sm-4">
                                <div className="card-body contact-us">
                                    <div className="">
                                        <h4 className="contact-us__header card-title">{formatMessage({ id: 'ContactUs.OurAddress', defaultMessage: 'Our Address' })}</h4>
                                        <div className="contact-us__address">
                                            <p>
                                                {formatMessage({ id: 'ContactUs.AddressContext', defaultMessage: 'Address Context' })}
                                                <br />
                                                {`${formatMessage({ id: 'ContactUs.Email', defaultMessage: 'Email' })}: ${formatMessage({ id: 'ContactUs.EmailContext' })}`}
                                                <br />
                                                {`${formatMessage({ id: 'ContactUs.PhoneNumber', defaultMessage: 'Phone Number' })}: ${formatMessage({ id: 'header.phone', defaultMessage: '' })}`}
                                            </p>

                                            {/* <p>
                                                <p className="badge badge-primary">{formatMessage({ id: 'ContactUs.OpeningHours', defaultMessage: 'Opening Hours' })}</p>
                                                <br />
                                                {formatMessage({ id: 'ContactUs.Monday2Friday' })}
                                                //<br />
                                                //Saturday: 8am-6pm
                                                //<br />
                                                //Sunday: 10am-4pm
                                            </p> */}

                                            {/* <p>
                                                <p className="badge badge-primary">{formatMessage({ id: 'ContactUs.Comment', defaultMessage: 'Comment' })}</p>
                                                <br />
                                                {formatMessage({ id: 'ContactUs.CommentContext' })}
                                            </p> */}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </React.Fragment>
        );
    }
}

export default injectIntl(SitePageContactUs);
