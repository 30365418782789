// react
import React from 'react';

// third-party
import classNames from 'classnames';
import PropTypes from 'prop-types';

// application
import { Check9x7Svg } from '../../svg';


class FilterCheckbox extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            Values: [],
            IsChecked: [],
        };
    }

    componentWillMount() {
        // const { tags } = this.state;
        const { items } = this.props;
        let tags = [];
        tags = items.filter(value => value.checked).map(value => value.tag);
        let ischecked = [];
        ischecked = items.map(value => value.checked);
        this.setState({ Values: tags, IsChecked: ischecked });
    }

    componentDidUpdate(prevProps) {
        // Typical usage (don't forget to compare props):
        const { items } = this.props;
        if (items !== prevProps.items) {
            let tags = [];
            tags = items.filter(value => value.checked).map(value => value.tag);
            let ischecked = [];
            ischecked = items.map(value => value.checked);
            this.setState({ Values: tags, IsChecked: ischecked });
        }
    }

    handleOnChangeEvent = (args) => {
        const { onChange } = this.props;
        // stop propagation if handler returns false
        if (onChange) {
            // bubble the event
            onChange(args);
        }
    }

    handleChange = (event) => {
        const { name, checked, id } = event.target;
        const { Values } = this.state;
        this.setState((state, props) => { state.IsChecked.splice(id, 1, !state.IsChecked[id]); });
        let updateValues;
        if (checked) {
            updateValues = [...Values, name];
            this.setState({ Values: updateValues });
        } else {
            updateValues = Values.filter(value => value !== name);
            this.setState({ Values: updateValues });
        }
        this.handleOnChangeEvent(updateValues);
    }

    render() {
        const { items } = this.props;
        const { IsChecked } = this.state;
        const itemsList = items.map((item, index) => {
            let count;

            if (item.count) count = <span className="filter-list__counter">{item.count}</span>;
            // const value = Values.push(item.tag);
            // if (item.checked) setValues(value);

            return (
                <label
                    key={item.id}
                    className={classNames('filter-list__item', {
                        'filter-list__item--disabled': item.disabled,
                    })}
                >
                    <span className="filter-list__input input-check">
                        <span className="input-check__body">
                            <input className="input-check__input" type="checkbox" id={index} name={item.tag} checked={IsChecked[index]} disabled={item.disabled} onChange={this.handleChange} />
                            <span className="input-check__box" />
                            <Check9x7Svg className="input-check__icon" />
                        </span>
                    </span>
                    <span className="filter-list__title">{item.label}</span>
                    {count}
                </label>
            );
        });

        return (
            <div className="filter-list">
                <div className="filter-list__list">
                    {itemsList}
                </div>
            </div>
        );
    }
}

FilterCheckbox.propTypes = {
    items: PropTypes.array,
    onChange: PropTypes.func,
};

export default FilterCheckbox;
