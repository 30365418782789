export const SORT_BY_MAX_PRICE = 'SORT_BY_MAX_PRICE';
export const SORT_BY_MIN_PRICE = 'SORT_BY_MIN_PRICE';
export const SORT_BY_MAX_WATER = 'SORT_BY_MAX_WATER';
export const SORT_BY_MIN_WATER = 'SORT_BY_MIN_WATER';
export const SORT_BY_MAX_HEAD = 'SORT_BY_MAX_HEAD';
export const SORT_BY_MIN_HEAD = 'SORT_BY_MIN_HEAD';
export const SET_MIN_PRICE = 'SET_MIN_PRICE';
export const SET_MAX_PRICE = 'SET_MAX_PRICE';
export const SET_MIN_WATER = 'SET_MIN_WATER';
export const SET_MAX_WATER = 'SET_MAX_WATER';
export const SET_MIN_HEAD = 'SET_MIN_HEAD';
export const SET_MAX_HEAD = 'SET_MAX_HEAD';
export const SET_CATEGORY = 'SET_CATEGORY';
export const SET_TAG = 'SET_TAG';
export const SET_BRAND = 'SET_BRAND';
export const SET_RESET = 'SET_RESET';
