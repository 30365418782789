// import { toast } from 'react-toastify';
import {
    SORT_BY_MAX_PRICE, SORT_BY_MIN_PRICE, SET_MIN_PRICE, SET_MAX_PRICE, SET_CATEGORY, SET_BRAND, SET_TAG,
    SET_MIN_WATER, SET_MAX_WATER, SET_MIN_HEAD, SET_MAX_HEAD, SORT_BY_MAX_WATER,
    SORT_BY_MIN_WATER, SORT_BY_MAX_HEAD, SORT_BY_MIN_HEAD, SET_RESET,
} from './filtersActionTypes';


export const sortByMaxPrice = () => ({
    type: SORT_BY_MAX_PRICE,
    sortBy: 'maxPrice',
});

export const sortByMinPrice = () => ({
    type: SORT_BY_MIN_PRICE,
    sortBy: 'minPrice',
});

export const sortByMaxWater = () => ({
    type: SORT_BY_MAX_WATER,
    sortBy: 'maxWater',
});

export const sortByMinWater = () => ({
    type: SORT_BY_MIN_WATER,
    sortBy: 'minWater',
});

export const sortByMaxHead = () => ({
    type: SORT_BY_MAX_HEAD,
    sortBy: 'maxHead',
});

export const sortByMinHead = () => ({
    type: SORT_BY_MIN_HEAD,
    sortBy: 'minHead',
});

export const setMinPrice = (startPrice = undefined) => ({
    type: SET_MIN_PRICE,
    startPrice,
});

export const setMaxPrice = (endPrice = undefined) => ({
    type: SET_MAX_PRICE,
    endPrice,
});

export const setMinWater = (startWater = undefined) => ({
    type: SET_MIN_WATER,
    startWater,
});

export const setMaxWater = (endWater = undefined) => ({
    type: SET_MAX_WATER,
    endWater,
});

export const setMinHead = (startHead = undefined) => ({
    type: SET_MIN_HEAD,
    startHead,
});

export const setMaxHead = (endHead = undefined) => ({
    type: SET_MAX_HEAD,
    endHead,
});

export const setCategory = (category = undefined) => ({
    type: SET_CATEGORY,
    category,
});

export const setBrand = (brand = undefined) => ({
    type: SET_BRAND,
    brand,
});

export const setTag = (tag = undefined) => ({
    type: SET_TAG,
    tag,
});

export const setReset = (tag = undefined) => ({
    type: SET_RESET,
    tag,
});

// export function cartAddItem(product, options = [], quantity = 1) {
//     // sending request to server, timeout is used as a stub
//     return dispatch => (
//         new Promise((resolve) => {
//             setTimeout(() => {
//                 dispatch(cartAddItemSuccess(product, options, quantity));
//                 resolve();
//             }, 2000);
//         })
//     );
// }

// export function cartRemoveItem(itemId) {
//     // sending request to server, timeout is used as a stub
//     return dispatch => (
//         new Promise((resolve) => {
//             setTimeout(() => {
//                 dispatch(cartRemoveItemSuccess(itemId));
//                 resolve();
//             }, 2000);
//         })
//     );
// }

// export function cartUpdateQuantities(quantities) {
//     // sending request to server, timeout is used as a stub
//     return dispatch => (
//         new Promise((resolve) => {
//             setTimeout(() => {
//                 dispatch(cartUpdateQuantitiesSuccess(quantities));
//                 resolve();
//             }, 2000);
//         })
//     );
// }
