import shopProducts from '../../data/shopProducts';
import {
    PRODUCT_FILTER_CATE,
} from './productsActionTypes';

const productsReducerDefaultState = shopProducts;

// const productsReducer = (state = productsReducerDefaultState) => (state);

// export default productsReducer;

export default function productsReducer(state = productsReducerDefaultState, action) {
    const { products } = action;
    const empty = [];
    switch (action.type) {
    case PRODUCT_FILTER_CATE:
        if (products.length === 0) {
            return Array.from(empty);
        }

        return Array.from(products); // {array: [{...products}]};


        // return addItem(state, action.product, action.options, action.quantity);

        // case CART_REMOVE_ITEM:
        //    return removeItem(state, action.itemId);

        // case CART_UPDATE_QUANTITIES:
        //    return updateQuantities(state, action.quantities);

    default:
        return state;
    }
}
