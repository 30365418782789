// react
import React from 'react';

// third-party
import { Helmet } from 'react-helmet';
import { injectIntl } from 'react-intl';

// blocks
// import BlockBanner from '../blocks/BlockBanner';
// import BlockBrands from '../blocks/BlockBrands';
import BlockCategories from '../blocks/BlockCategories';
import BlockFeatures from '../blocks/BlockFeatures';
// import BlockPosts from '../blocks/BlockPosts';
// import BlockProductColumns from '../blocks/BlockProductColumns';
// import BlockProducts from '../blocks/BlockProducts';
import BlockSlideShow from '../blocks/BlockSlideShow';
// import BlockTabbedProductsCarousel from '../blocks/BlockTabbedProductsCarousel';

// data stubs
// import categories from '../../data/productsBlockCategories';
// import posts from '../../data/blogPosts';
// import products from '../../data/productsProducts';
import theme from '../../data/theme';


function HomePageTwo({ intl }) {
    const { formatMessage } = intl;
    // const columns = [
    //     {
    //         title: 'Top Rated Products',
    //         products: products.slice(0, 3),
    //     },
    //     {
    //         title: 'Special Offers',
    //         products: products.slice(3, 6),
    //     },
    //     {
    //         title: 'Bestsellers',
    //         products: products.slice(6, 9),
    //     },
    // ];
    const categories = [
        {
            title: formatMessage({ id: 'homepage.PopularProductions.P1.title' }),
            url: '/products',
            products: formatMessage({ id: 'homepage.PopularProductions.P1.content' }),
            image: 'images/categories/category-1.jpg',
        },
        {
            title: formatMessage({ id: 'homepage.PopularProductions.P2.title' }),
            url: '/products',
            products: formatMessage({ id: 'homepage.PopularProductions.P2.content' }),
            image: 'images/categories/category-2.jpg',
        },
        {
            title: formatMessage({ id: 'homepage.PopularProductions.P3.title' }),
            url: '/products',
            products: formatMessage({ id: 'homepage.PopularProductions.P3.content' }),
            image: 'images/categories/category-4.jpg',
        },
        {
            title: formatMessage({ id: 'homepage.PopularProductions.P4.title' }),
            url: '/products',
            products: formatMessage({ id: 'homepage.PopularProductions.P4.content' }),
            image: 'images/categories/category-3.jpg',
        },
        {
            title: formatMessage({ id: 'homepage.PopularProductions.P5.title' }),
            url: '/products',
            products: formatMessage({ id: 'homepage.PopularProductions.P5.content' }),
            image: 'images/categories/category-5.jpg',
        },
        {
            title: formatMessage({ id: 'homepage.PopularProductions.P6.title' }),
            url: '/products',
            products: formatMessage({ id: 'homepage.PopularProductions.P6.content' }),
            image: 'images/categories/category-6.jpg',
        },
    ];

    return (
        <React.Fragment>
            {/* <Helmet>
                <title>{`${theme.name}`}</title>
            </Helmet> */}

            <BlockSlideShow />

            <BlockFeatures layout="boxed" />

            {/* <BlockTabbedProductsCarousel title="Featured Products" layout="grid-5" rows={2} />

            <BlockBanner />

            <BlockProducts
                title="Bestsellers"
                layout="large-last"
                featuredProduct={products[0]}
                products={products.slice(1, 7)}
            /> */}

            {/* <BlockCategories title={formatMessage({ id: 'homepage.PopularProductions' })} layout="compact" categories={categories} /> */}

            {/* <BlockTabbedProductsCarousel title="New Arrivals" layout="grid-5" />

            <BlockPosts title="Latest News" layout="grid-nl" posts={posts} />

            <BlockBrands />

            <BlockProductColumns columns={columns} /> */}
        </React.Fragment>
    );
}

export default injectIntl(HomePageTwo);
