import { combineReducers } from 'redux';

// reducers
import cartReducer from './cart';
import compareReducer from './compare';
import currencyReducer from './currency';
import localeReducer from './locale';
import mobileMenuReducer from './mobile-menu';
import quickviewReducer from './quickview';
import wishlistReducer from './wishlist';
import productsReducer from './products';
import { filtersReducer } from './filters';

export default combineReducers({
    cart: cartReducer,
    compare: compareReducer,
    currency: currencyReducer,
    locale: localeReducer,
    mobileMenu: mobileMenuReducer,
    quickview: quickviewReducer,
    wishlist: wishlistReducer,
    products: productsReducer,
    filters: filtersReducer,
});
