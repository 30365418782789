// react
import React from 'react';

// third-party
import { injectIntl } from 'react-intl';

// data stubs
//import theme from '../../data/theme';


function FooterContacts({ intl }) {
    const { formatMessage } = intl;
    return (
        <div className="site-footer__widget footer-contacts">
            <h5 className="footer-contacts__title">{formatMessage({ id: 'menu.Contact Us' })}</h5>

            <div className="footer-contacts__text">
                {formatMessage({ id: 'ContactUs.CommentContext' })}
            </div>

            <ul className="footer-contacts__contacts">
                <li>
                    <i className="footer-contacts__icon fas fa-globe-americas" />
                    {formatMessage({ id: 'ContactUs.AddressContext', defaultMessage: 'Address Context' })}
                </li>
                <li>
                    <i className="footer-contacts__icon far fa-envelope" />
                    <a href={`mailto:${formatMessage({ id: 'ContactUs.EmailContext' })}`}>{formatMessage({ id: 'ContactUs.EmailContext' })}</a>
                </li>
                <li>
                    <i className="footer-contacts__icon fas fa-mobile-alt" />
                    {`${formatMessage({ id: 'header.phone' })}, ${formatMessage({ id: 'header.phone2' })}`}
                </li>
                <li>
                    <i className="footer-contacts__icon fas fa-fax" />
                    {formatMessage({ id: 'header.fax' })}
                </li>
                <li>
                    <i className="footer-contacts__icon far fa-clock" />
                    {formatMessage({ id: 'ContactUs.Monday2Friday' })}
                    {/* Mon-Sat 10:00pm - 7:00pm */}
                </li>
            </ul>
        </div>
    );
}

export default injectIntl(FooterContacts);
